import React, { useMemo, useState ,useCallback} from "react";
import MaterialReactTable from "material-react-table";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Edit, Visibility } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import {
  IconButton,
  Tooltip,
  Toolbar,
  Box,
  Button,
} from "@mui/material";


// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

//Date Picker Imports
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import agent from "api/agent";


const Example = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL("api/Cupon/Coupons", "https://api.ibu.az/");
      fetchURL.searchParams.set("start", `${pagination.pageIndex + 1}`);
      fetchURL.searchParams.set("size", `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      
      const response = await agent.Coupon.getAllCoupons(fetchURL.search);
      return response;
    },
    keepPreviousData: true,
  });

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => new Date(row.endDate), //convert to Date for sorting and filtering
        id: "EndDate",
        header: "Bitmə tarixi",
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: "Filter Mode: Less Than",
                  sx: { minWidth: "120px" },
                  variant: "standard",
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
      {
        header: "Kupon adı",
        accessorKey: "name",
        id: "Name",
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              alt="avatar"
              height={50}
              src={row.original.image}
              loading="lazy"
              style={{ borderRadius: "50%" }}
            />
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        header: "Kupon haqqında",
        accessorKey: "name",
        id: "Name",
      },
      {
        header: "Hədəf",
        accessorKey: "targetName",
        id: "TargetName",
      },
      {
        header: "Kupon kodu",
        accessorKey: "code",
        id: "Code",
      },
      {
        accessorKey: "discountPercentage",
        header: "Endirim faizi",
        id: "DiscountPercentage",
      },
    ],
    []
  );


  const handleDeleteRow = useCallback(
    async (table) => {
      if (
        !confirm(`Bu ${table.getSelectedRowModel().flatRows.length} kuponu silməyə əminsiniz?`)
      ) {
        return;
      }
      let selected = table.getSelectedRowModel().flatRows.map((row) => {
        return row.original.id;
      });
    try {
      await agent.Coupon.delete(selected);
      refetch()
    } catch (error) {
      
    }
    },
    [refetch]
  );



  const rowsPerPageOptions = [];
  for (let i = 10; i <= data?.totalCount; i += 10) {
    rowsPerPageOptions.push(i);
  }
  rowsPerPageOptions.push(data?.totalCount);

  return (
    <MaterialReactTable
      columns={columns}
      data={data?.items ?? []}
      initialState={{ showColumnFilters: true }}
      manualFiltering
      manualPagination
      manualSorting
      enableRowSelection
      muiSelectCheckboxProps={{
        color: "secondary",
      }}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      renderTopToolbarCustomActions={({ table }) => {
        return (
          <div style={{ display: "flex", gap: "0.5rem" }}>
          <Button
            color="error"
            disabled={table.getSelectedRowModel().flatRows.length < 1}
            onClick={() => handleDeleteRow(table)}
            variant="contained"
          >
            Silmək
          </Button>
          <Tooltip arrow title="Refresh Data">
            <IconButton onClick={() => refetch()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
        );
      }}
      muiTablePaginationProps={{
        rowsPerPageOptions: rowsPerPageOptions,
        showFirstButton: true,
        showLastButton: true,
      }}
      rowCount={data?.totalCount ?? 0}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isFetching,
        sorting,
      }}
    />
  );
};

const Coupons = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Kuponlar
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Coupons;
