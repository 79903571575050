import React, { useState } from 'react';
import { Box, IconButton, Paper, styled } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';

const MainImage = styled(Paper)(({ theme }) => ({
   // 16:9 aspect ratio
  maxWidth: '450px',
  maxHeight: '300px',
  paddingTop: '75%', // 4:3 aspect ratio
  position: 'relative',
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const Image = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const ThumbnailImage = styled(Paper)(({ theme }) => ({
  width: '60px',
  height: '60px',
  position: 'relative',
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  cursor: 'pointer',
}));

const ProductDetailPhotos = ({ images }) => {
  const [mainImage, setMainImage] = useState(images[0]);
  const [thumbnailStartIdx, setThumbnailStartIdx] = useState(0);
  const thumbnailsToShow = 5;

  const handleThumbnailClick = (image) => {
    setMainImage(image);
  };

  const handlePrevClick = () => {
    setThumbnailStartIdx((prev) => Math.max(prev - 1, 0));
  };

  const handleNextClick = () => {
    setThumbnailStartIdx((prev) => Math.min(prev + 1, images.length - thumbnailsToShow));
  };

  return (
    <Box>
      <MainImage>
        <Image src={mainImage} alt="Main Product" />
      </MainImage>
      <Box display="flex" alignItems="center">
        <IconButton onClick={handlePrevClick} disabled={thumbnailStartIdx === 0}>
          <ChevronLeftIcon />
        </IconButton>
        <Box display="flex">
          {images.slice(thumbnailStartIdx, thumbnailStartIdx + thumbnailsToShow).map((image, idx) => (
            <ThumbnailImage key={idx} onClick={() => handleThumbnailClick(image)}>
              <Image src={image} alt={`Thumbnail ${idx + 1}`} />
            </ThumbnailImage>
          ))}
        </Box>
        <IconButton
          onClick={handleNextClick}
          disabled={thumbnailStartIdx + thumbnailsToShow >= images.length}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ProductDetailPhotos;