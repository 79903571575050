import React, { useMemo, useState, useCallback,useEffect } from "react";
import MaterialReactTable from "material-react-table";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Edit, AccountCircle, Percent } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";

// Agent import
import agent from "api/agent";

import {
  IconButton,
  Tooltip,
  Box,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  ListItemIcon, 
  Typography,
  TextField,
} from "@mui/material";
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

// Formik and Yup validation
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import SelectBox from "components/MDSelectBox";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import UserDetail from "../../profile/index";



// const queryClient = new QueryClient();
const Example = () => {
  const [columnFilters, setColumnFilters] = useState([
    {"id":"UserType","value":"1"}
  ]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [addCouponCodeModalOpen, setAddCouponCodeModalOpen] = useState(false);
  const [addCommissionModalOpen, setAddCommissionModalOpen] = useState(false);
  const [increaseOrDecreaseBonusModalOpen, setIncreaseOrDecreaseBonusModalOpen] = useState(false);
  const [UserDetailModalOpen, setUserDetailModalOpen] = useState(false);
  const [cosmetologistId, setCosmetologistId] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        "api/Users/get-users-with-query-model",
        "https://api.ibu.az/"
      );
      fetchURL.searchParams.set("UserType", `Cosmetologist`);
      fetchURL.searchParams.set("start", `${pagination.pageIndex + 1}`);
      fetchURL.searchParams.set("size", `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      const response = await agent.User.GetUsersWithQueryModel(fetchURL.search);
      return response;
    },
    keepPreviousData: true,
  });

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.nameSurname}`, //accessorFn used to join multiple data into a single cell
        id: "NameSurname", //id is still required when using accessorFn instead of accessorKey
        header: "Ad Soyad",
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
           {
            row.original.profilePhoto ? (
              <img
                src={row.original.profilePhoto}
                alt="profile"
                style={{
                  width: "2.5rem",
                  height: "2.5rem",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <AccountCircle
                sx={{
                  width: "2.5rem",
                  height: "2.5rem",
                  borderRadius: "50%",
                  fontSize: "2.5rem",
                  fontWeight: 500,
                  
                }}
              />
            )
           }
            <Typography variant='inherit'>{renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "bonus",
        header: "Bonus",
        id: "Bonus",
        size: 5,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              color: '#fff',          
              backgroundColor: theme.palette.success.main,
              borderRadius: "5px",
              padding: "0.25rem 0.5rem",
              fontSize: "0.75rem",
              fontWeight: 500,
              lineHeight: 1.75,
              letterSpacing: "0.02857em",
              textTransform: "uppercase"
            })}
          >
            {cell.getValue()} AZN
          </Box>
        ),
      },
      {
        accessorKey: "commissionRate",
        header: "Komissiya",
        id: "CommissionRate",
        size: 4,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              color: '#fff',          
              backgroundColor: theme.palette.success.main,
              borderRadius: "5px",
              padding: "0.25rem 0.5rem",
              fontSize: "0.75rem",
              fontWeight: 500,
              lineHeight: 1.75,
              letterSpacing: "0.02857em",
              textTransform: "uppercase"
            })}
          >
            {cell.getValue()}%
          </Box>
        ),
      }
    ],
    []
  );


   const [cosmetologistIds, setCosmetologistIds] = useState([]);

   const handleAddUserToCoupon = useCallback((table) => {
     const cosmetologistId = table.getSelectedRowModel().flatRows.map((row) => row.original.id);
     setCosmetologistIds(cosmetologistId);
     setAddCouponCodeModalOpen(true);
   }, []);

     const rowsPerPageOptions = [];
     for (let i = 10; i <= data?.totalCount; i += 10) {
       rowsPerPageOptions.push(i);
     }
     rowsPerPageOptions.push(data?.totalCount);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data?.items ?? []}
        initialState={{ showColumnFilters: true }}
        manualFiltering
        manualPagination
        manualSorting
        enableRowActions
        enableRowSelection
        muiSelectCheckboxProps={{
          color: "secondary",
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderRowActionMenuItems={({ closeMenu,row }) => [
          <MenuItem
            key={0}
            onClick={() => {
              setCosmetologistId(row.original.id);
              setUserDetailModalOpen(true);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            Profilə baxın
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
               setCosmetologistId(row.original.id);
               setAddCommissionModalOpen(true);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Percent />
            </ListItemIcon>
            Komissiya təyin et
          </MenuItem>,
            <MenuItem
            key={2}
            onClick={() => {
              setCosmetologistId(row.original.id);
              setIncreaseOrDecreaseBonusModalOpen(true);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <AccountBalanceWalletIcon/>
            </ListItemIcon>
            Bonusu İdarə et
          </MenuItem>,

        ]}
         
     
        renderTopToolbarCustomActions={({ table }) => {
          const handleDeactivate = () => {
            let selected = table.getSelectedRowModel().flatRows.map((row) => {
              return row.original.id;
            });
            alert(JSON.stringify(selected));
          };

          return (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <Button
                color="error"
                sx={{
                  color: "white",
                  backgroundColor: "red",
                }}
                disabled={table.getSelectedRowModel().flatRows.length < 1}
                onClick={handleDeactivate}
                variant="contained"
              >
                Silmək
              </Button>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>

              <Button
                color="info"
                onClick={() => {
                  handleAddUserToCoupon(table);
                }}
                variant="contained"
                disabled={table.getSelectedRowModel().flatRows.length < 1}
              >
                Kupon Əlavə et
              </Button>
            </div>
          );
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: rowsPerPageOptions,
          showFirstButton: true,
          showLastButton: true,
        }}
        rowCount={data?.totalCount ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
      <CreateCouponCodeModal
        open={addCouponCodeModalOpen}
        onClose={() => setAddCouponCodeModalOpen(false)}
        cosmetologistId={cosmetologistIds}
      />

      <AddCommissionModal
        open={addCommissionModalOpen}
        onClose={() => setAddCommissionModalOpen(false)}
        cosmetologistId={cosmetologistId}
        refetch={refetch}
      />

      <IncreaseOrDecreaseBonus
        open={increaseOrDecreaseBonusModalOpen}
        onClose={() => setIncreaseOrDecreaseBonusModalOpen(false)}
        cosmetologistId={cosmetologistId}
        refetch={refetch}
      />

      <UserDetailModal
        open={UserDetailModalOpen}
        onClose={() => setUserDetailModalOpen(false)}
        cosmetologistId={cosmetologistId}
      />
    </>
  );
};

const CreateCouponCodeModal = ({ open, onClose, cosmetologistIds }) => {
 const [coupons, setCoupons] = useState([]);
  useEffect(() => {
   agent.Coupon.list().then((response) => {
     const coupon = response?.map((coupon) => {
       return {
         value: coupon.id,
         label: coupon.name,
       };
     });
     setCoupons(coupon);
   });
 }, []);

 const handleSubmit = (values) => {
   values.cosmetologistIds = cosmetologistIds;
   agent.Coupon.assignCouponToUser(values).then((response) => {
     if (response) {
       console.log(response);
       onClose();
     }
   });
 };
 const initialValues = {
   couponId: "",
 };

 return (
   <Formik initialValues={initialValues} onSubmit={handleSubmit}>
     {({
       values,
       errors,
       touched,
       setFieldValue,
       setFieldTouched,
       handleChange,
       handleBlur,
       handleSubmit,
       isSubmitting,
     }) => (
       <Form>
         <Dialog open={open}>
           <DialogTitle textAlign="center">
             <MDTypography variant="h6">Kupon Əlavə et</MDTypography>
           </DialogTitle>
           <DialogContent>
             <Stack
               sx={{
                 width: "100%",
                 minWidth: { xs: "300px", sm: "360px", md: "400px" },
                 gap: "1.5rem",
               }}
             >
               <Grid item xs={12} md={6}>
                 <MDBox mb={2}>
                   <Field
                     name="couponId"
                     label="Kuponlar"
                     component={SelectBox}
                     options={coupons ? coupons : []}
                     onChange={(value) => setFieldValue("couponId", value)}
                   />
                   {touched.couponId && errors.couponId && errorMessages(errors.couponId)}
                 </MDBox>
               </Grid>
             </Stack>
           </DialogContent>
           <DialogActions sx={{ p: "1.25rem" }}>
             <Button onClick={onClose}>Cancel</Button>
             <Button color="info" onClick={handleSubmit} variant="contained">
               Əlavə Et
             </Button>
           </DialogActions>
         </Dialog>
       </Form>
     )}
   </Formik>
 );
};

const AddCommissionModal = ({ open, onClose, cosmetologistId,refetch}) => {
     const addCommissionSchema = Yup.object().shape({
      commision: Yup.number()
          .typeError("Komissiya dərəcəsi rəqəm olmalıdır")
          .min(0)
          .max(100)
          .required("Komissiya dərəcəsi təyin edin"),
      });
      
      const addCommissionFormik = useFormik({
        initialValues: {
          commision: 0,
        },
        validationSchema: addCommissionSchema,
        onSubmit: (values) => {
          values.userId = cosmetologistId;
          agent.User.addCommissionRate(values).then((response) => {
            if (response) {
              onClose();
              refetch();
            }
          });
        },
      });

      return (
        <Dialog open={open}>
          <DialogTitle textAlign="center">
            <MDTypography variant="h6">Komissiya təyin et</MDTypography>
          </DialogTitle>
          <DialogContent>
            <Stack
              sx={{
                width: "100%",
                minWidth: { xs: "300px", sm: "360px", md: "400px" },
                gap: "1.5rem",
              }}
            >
              <Grid item xs={12} md={6}>
                <MDBox mb={2} mt={2}>
                  <MDInput
                    name="commision"
                    label="Komissiya dərəcəsi"
                    value={addCommissionFormik.values.commision}
                    onChange={addCommissionFormik.handleChange}
                    onBlur={addCommissionFormik.handleBlur}
                    error={
                      addCommissionFormik.touched.commision &&
                      Boolean(addCommissionFormik.errors.commision)
                    }
                    helperText={
                      addCommissionFormik.touched.commision &&
                      addCommissionFormik.errors.commision
                    }
                  />
                </MDBox>
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ p: "1.25rem" }}>
            <Button onClick={onClose}>Bağla</Button>
            <Button
              color="info"
              onClick={addCommissionFormik.handleSubmit}
              variant="contained"
            >
              Əlavə Et
            </Button>
          </DialogActions>
        </Dialog>
      );

};

const IncreaseOrDecreaseBonus = ({ open, onClose, cosmetologistId,refetch }) => {
  const  IncreaseOrDecreaseSchema = Yup.object().shape({
    bonus: Yup.number()
        .typeError("Bonus rəqəm olmalıdır")
        .min(0)
        .required("Bonusu təyin edin"),
    isIncrease: Yup.boolean()
        .required("Artırma və ya azaltma seçin"),
    });
  const  IncreaseOrDecreaseFormik = useFormik({
     initialValues: {
        isIncrease: false,
        bonus: 0,
      },
      validationSchema: IncreaseOrDecreaseSchema,
      onSubmit: (values) => {
        values.userId = cosmetologistId;
        agent.User.increaseOrDecreaseBonus(values).then((response) => {
          if (response) {
            refetch();
            onClose();
          }
        });
      },
    });

    return (
      <Dialog open={open}>
        <DialogTitle textAlign="center">
          <MDTypography variant="h6">Bonusu İdarə et</MDTypography>
        </DialogTitle>
        <DialogContent>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <Grid item xs={12} md={6}>
              <MDBox mb={2} mt={2}>
                <MDInput
                  name="bonus"
                  label="Bonus"
                  value={IncreaseOrDecreaseFormik.values.bonus}
                  onChange={IncreaseOrDecreaseFormik.handleChange}
                  onBlur={IncreaseOrDecreaseFormik.handleBlur}
                  error={
                    IncreaseOrDecreaseFormik.touched.bonus &&
                    Boolean(IncreaseOrDecreaseFormik.errors.bonus)
                  }
                  helperText={
                    IncreaseOrDecreaseFormik.touched.bonus &&
                    IncreaseOrDecreaseFormik.errors.bonus
                  }
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={2} mt={2}>
               <SelectBox
                  defaultValue={{ value: false, label: "Azaltma" }}
                  name="isIncrease"
                  label="Artırma və ya azaltma"
                  options={[
                    { value: true, label: "Artırma" },
                    { value: false, label: "Azaltma" },
                  ]}
                  onChange={(value) => {
                    IncreaseOrDecreaseFormik.setFieldValue("isIncrease", value);
                  }}
                />
              </MDBox>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: "1.25rem" }}>
          <Button onClick={onClose}>Bağla</Button>
          <Button
            color="info"
            onClick={IncreaseOrDecreaseFormik.handleSubmit}
            variant="contained"
          >
           {IncreaseOrDecreaseFormik.values.isIncrease ? "Artır" : "Azalt"}
          </Button>
        </DialogActions>
      </Dialog>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserDetailModal = ({ open, onClose, cosmetologistId }) => {
  return (
      <Dialog 
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <MDTypography
           variant="h6"
            sx={{
              fontWeight: 600,
              fontSize: "1.25rem",
              lineHeight: "1.6",
              letterSpacing: "0.0075em",
              color: "rgba(0, 0, 0, 0.87)",
            }}
           >
            Kosmetoloq haqqında ətraflı məlumat
            </MDTypography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: "red",
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.12)",
              boxShadow: "none",
              '&:hover': {
                backgroundColor: "white",
                boxShadow: "none",
              },
              '&:active': {
                boxShadow: "none",
                backgroundColor: "white",
              },

            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <UserDetail id={cosmetologistId} />
        </DialogContent>
      </Dialog> 
  );
}

const Cosmetologist = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Kosmetoloqlar
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Cosmetologist;
