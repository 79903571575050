import React, { useMemo, useState, useCallback } from "react";
import MaterialReactTable from "material-react-table";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Edit, Visibility, Add, Delete } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";

// @mui icons
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';

// react-router-dom components
import {useNavigate } from "react-router-dom";

// Agent import
import agent from "api/agent";

import {
  IconButton,
  Tooltip,
  Toolbar,
  Box,
  Button,
} from "@mui/material";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSwitch from "components/MDSwitch";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const Example = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        "api/appVersionmanagment/versions",
        "https://api.ibu.az/"
      );
      fetchURL.searchParams.set('start',`${pagination.pageIndex + 1}`);
      fetchURL.searchParams.set('size', `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      const response = await agent.AppVersionManagment.list(fetchURL.search);
      return response;
    },
    keepPreviousData: true,
  });

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.platform}`,
        id: "Platform",
        header: "Platform",
        size: 250,
        Cell: ({row }) => (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "1rem",
                }}
            >
                {row.original.platform === "android" ? (
                    <AndroidIcon
                        fontSize="medium"
                        htmlColor="#3ADD85"
                     />
                ) : (
                    <AppleIcon
                      fontSize="medium"
                     htmlColor="#999999"
                     />
                )}
            </Box>
        ),
      },
      {
        accessorFn: (row) => `${row.version}`, 
        id: "Version",
        header: "Versiya",
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
           <MDTypography
                variant="body2"
                fontWeight="bold"
                style={{
                    borderRadius: "0.5rem",
                    padding: "0.15em 0.3em",
                    color: "#fff",
                    backgroundColor: "#8b919c",
                    border: "1px solid #99999928",
                    width: "max-content",
                }}
                >
              
                {renderedCellValue}
                </MDTypography>
        ),
      },
      {
        accessorKey: "isForceUpgrade",
        header: "Məcburi yeniləmə",
        id: "IsForceUpgrade",
        Cell: ({row }) => ( 
            <MDTypography
                variant="body2"
                fontWeight="medium"
                style={{
                    borderRadius: "0.5rem",
                    padding: "0.15em 0.3em",
                    color: "#fff",
                    backgroundColor: row.original.isForceUpgrade ? "#3ADD85" : "#8b919c",
                    border: "1px solid #99999928",
                    width: "max-content",
                }}
                >
                {row.original.isForceUpgrade ? "Bəli" : "Xeyr"}
                </MDTypography>
        ),
      },
      {
        accessorKey: "isEnabled",
        header: "Aktiv",
        id: "IsEnabled",
        Cell: ({row }) => (
            <MDSwitch
                checked={row.original.isEnabled}
                inputProps={{ "aria-label": "controlled" }}
                />
        ),
      },
      
    ],
    []
  );

  const handleDeleteRow = useCallback(
    async (ids) => {
      if (
        !confirm(
          `${ids.length} qədər brand silmək istədiyinizə əminsiniz?`
        )
      ) {
        return;
      }
       try {
          await agent.Brand.delete(ids);
          refetch();
        } catch (error) {
          console.log(error);
        }
    },
    [refetch]
  );

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      tableData[row.index] = values;
      //send/receive api updates here, then refetch or update local table data for re-render
      setTableData([...tableData]);
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

    const rowsPerPageOptions = [];
    for (let i = 10; i <= data?.totalCount; i += 10) {
      rowsPerPageOptions.push(i);
    }
    rowsPerPageOptions.push(data?.totalCount);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data?.items ?? []}
        initialState={{ showColumnFilters: true }}
        manualFiltering
        manualPagination
        manualSorting
        enableRowActions
        enableSelectAll
        enableRowSelection
        enableColumnFilterModes
        editingMode="modal" //default
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderRowActions={({ row, table }) => (
          <Toolbar>
            <IconButton onClick={() => alert(row.original.id)}>
              <Visibility
                sx={{
                  color: "green",
                }}
              />
            </IconButton>
            <IconButton 
              onClick={() => {
                navigate("/brand/edit", {
                  state: {
                    id: row.original.id,
                    name: row.original.name,
                    companyId: row.original.companyId,
                    companyName: row.original.companyName,
                    logo: row.original.logo,
                  },
              }
            )}}
             >
              <Edit
                sx={{
                  color: "orange",
                }}
              />
            </IconButton>
          </Toolbar>
        )}
        renderTopToolbarCustomActions={({ table }) => {
          const handleDeactivate = () => {
            const ids = table.getSelectedRowModel().flatRows.map((item)=>{
              return item.original.id;
            })
            handleDeleteRow(ids);
          };

          return (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <Button
                color="error"
                disabled={table.getSelectedRowModel().flatRows.length < 1}
                onClick={handleDeactivate}
                variant="contained"
              >
                Silmək
              </Button>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: rowsPerPageOptions,
          showFirstButton: true,
          showLastButton: true,
        }}
        rowCount={data?.totalCount ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
    </>
  );
};

const AppVersionManagment = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
              Tətbiq Versiyasının İdarə Edilməsi
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default AppVersionManagment;
