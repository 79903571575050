// Core components
import React, { useState, useEffect,useCallback  } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Ibeauty Admin Dashboard React example components
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

import agent from "api/agent";

function Overview({id}) {
  const [user, setUser] = useState({});
    const fetchUser = useCallback(async () => {
        const response = await agent.User.fullDetails(id);
        setUser(response);
    }, [id]);

    useEffect(() => {
        fetchUser();
    }
    ,[fetchUser]);

    
  return (
       <Header 
       nameSurname={user?.nameSurname} 
       referralCode={user?.referralCode}
       profilePhoto={user?.profilePhoto}
       >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
          <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
  <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
  <MDBox display="flex" py={1} pr={2}>
    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
      Email: &nbsp;
    </MDTypography>
    <MDTypography variant="button" fontWeight="regular" color="text">
      &nbsp; {user?.email}
    </MDTypography>
  </MDBox>
  
  <MDBox display="flex" py={1} pr={2}>
    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
      Telefon: &nbsp;
    </MDTypography>
    <MDTypography variant="button" fontWeight="regular" color="text">
      &nbsp; {user?.phoneNumber}
    </MDTypography>
  </MDBox>
  
  <MDBox display="flex" py={1} pr={2}>
    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
      Bonus: &nbsp;
    </MDTypography>
    <MDTypography variant="button" fontWeight="regular" color="text">
      &nbsp; {user?.bonus}
    </MDTypography>
  </MDBox>
  
  <MDBox display="flex" py={1} pr={2}>
    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
      Komissiya: &nbsp;
    </MDTypography>
    <MDTypography variant="button" fontWeight="regular" color="text">
      &nbsp; {user?.commission ? user?.commission : 0 +"%"}
    </MDTypography>
  </MDBox>
  </MDBox>
            </Grid>
       {user?.userType === "Cosmetologist" && (
         <Grid item  xs={12} xl={4}>
         <MDBox p={2}>
           <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
               Kosmetoloqluğu təstiq edən sənədlər
           </MDTypography>
         
         <ImageListItem>
             <img
               srcSet={`${user?.document[0]?.identityCard}`}
               src={`${user?.document[0]?.identityCard}`}
               alt="Şəxsiyyət vəsiqəsi"
               loading="lazy"
             />
             <ImageListItemBar
               title="Şəxsiyyət vəsiqəsi"
               actionIcon={
                 <IconButton
                   sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                 >
                   <InfoIcon />
                 </IconButton>
               }
             />
           </ImageListItem>
            <ImageListItem>
              <img
                srcSet={`${user?.document[0]?.certificate}`}
                src={`${user?.document[0]?.certificate}`}
                alt="Sertifikat"
                loading="lazy"
              />
              <ImageListItemBar
                title="Diplom"
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>

         </MDBox>
               </Grid>
        )}
        <Grid item xs={12} xl={4}>
          <ProfilesList title="Userlərin Səbətləri" profiles={profilesListData} shadow={false} />
        </Grid>
          </Grid>
        </MDBox>
      </Header>
  );
}

export default Overview;
