import { useMemo, useEffect, useState } from "react";
import { useSelector} from 'react-redux';
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

function DataTable({
  onNextPage,
  hasNextPage,
  totalRows,
  type,
  loading,
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];

  const columns = useMemo(() => table.columns, [table]);
  const data = useSelector((state) => state.tableData[type])

  const tableInstance = useTable(
    { 
      columns, data, 
      initialState: { pageIndex: 0 } , 
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  let pageOptions = useMemo(() => {
    const pageCount = Math.ceil(totalRows / pageSize);
    return Array.from(new Array(pageCount), (_, i) => i);
  }, [totalRows, pageSize]);


   const handleGoToPage = (page) => {
    if (hasNextPage) {
        onNextPage(page + 1, pageSize).then(() => {
        gotoPage(Number(page));
      });
    }else{
    gotoPage(page);
    }
  };

  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  const setEntriesPerPage = (value) => setPageSize(value);

  const renderPagination = pageOptions?.map((option) => (
    <MDPagination
     item
     key={option}
     onClick={() => handleGoToPage(option)}
     active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  )); 



  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : handleGoToPage(Number(value));

  const customizedPageOptions = pageOptions.map((option) => option + 1);

  const handleInputPaginationValue = ({ target: value }) => handleGoToPage(Number(value.value - 1));

  const [search, setSearch] = useState(globalFilter);

  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }
    return sortedValue;
  };

  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
    {entriesPerPage || canSearch ? (
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        {entriesPerPage && (
          <MDBox display="flex" alignItems="center">
            <Autocomplete
              disableClearable
              value={pageSize.toString()}
              options={entries}
              onChange={(event, newValue) => {
                setEntriesPerPage(parseInt(newValue, 10));
              }}
              size="small"
              sx={{ width: "5rem" }}
              renderInput={(params) => <MDInput {...params} />}
            />
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;entries per page
            </MDTypography>
          </MDBox>
        )}
        {canSearch && (
          <MDBox width="12rem" ml="auto">
            <MDInput
              placeholder="Search..."
              value={search}
              size="small"
              fullWidth
              onChange={({ currentTarget }) => {
                setSearch(search);
                onSearchChange(currentTarget.value);
              }}
            />
          </MDBox>
        )}
      </MDBox>
    ) : null}
    <Table {...getTableProps()}>
      <MDBox component="thead">
        {headerGroups?.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <DataTableHeadCell
                {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                width={column.width ? column.width : "auto"}
                align={column.align ? column.align : "left"}
                sorted={setSortedValue(column)}
              >
                {column.render("Header")}
              </DataTableHeadCell>
            ))}
          </TableRow>
        ))}
      </MDBox>
      <TableBody {...getTableBodyProps()}>
        {page?.map((row, key) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row?.cells.map((cell) => (
                <DataTableBodyCell
                  noBorder={noEndBorder && rows.length - 1 === key}
                  align={cell.column.align ? cell.column.align : "left"}
                  {...cell.getCellProps()}
                >
                  {cell.render("Cell")}
                </DataTableBodyCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>

    <MDBox
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", sm: "center" }}
      p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
    >
    {showTotalEntries && (
      <MDBox mb={{ xs: 3, sm: 0 }}>
        <MDTypography variant="button" color="secondary" fontWeight="regular">
          Showing {entriesStart} to {entriesEnd} of {totalRows} entries
        </MDTypography>
      </MDBox>
    )}
    {pageOptions.length > 1  && (
          <MDPagination
            variant={pagination?.variant ? pagination?.variant : "gradient"}
            color={pagination?.color ? pagination?.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}
export default DataTable;