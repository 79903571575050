import React from 'react';
// Ibeauty Admin agen 
import agent from "api/agent";

// @mui material components
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import Icon from "@mui/material/Icon";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTextarea from "components/MDTextarea";

import MDSnackbar from "components/MDSnackbar";

// Redux hooks 
import {removeRow }  from "redux/tableDataSlice";
import { useDispatch } from 'react-redux'
function UserDocumentViewer({doc,setOpen}) {
  const [successSB, setSuccessSB] =  React.useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

    const dispatch = useDispatch()

    const [RejReason, setRejReason] = React.useState('');
    const [Seria, setSeria] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState('');
    // user approval  or reject
    const handleApproval = () => {
        setLoading(true);
        agent.ApprovalRequests.ApproveOrReject(
           { 
             userId: doc.userId,
             isApproved: true,
             identityCardNumber: Seria,
           }
        ).then(() => {
            setOpen(false);
            setMessage('Təsdiq edildi');
            setSuccessSB(true);
            dispatch(removeRow(doc.userId));
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        })
    }
    const handleReject = () => {
        setLoading(true);
        agent.ApprovalRequests.ApproveOrReject(
           { 
             userId: doc.userId,
             isApproved: false,
             rejectReason: RejReason,
           }
        ).then((response) => {
            setOpen(false);
            setMessage('Rədd edildi');
            setSuccessSB(true);
            dispatch(removeRow(doc.userId));
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }
  return (
    <>
  <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div style={{ margin: '10px' }}>
              <MDTypography gutterBottom>
                Serifikat
              </MDTypography>
              <img src={doc.certificate} alt="image" style={{ maxWidth: "100%", maxHeight: "400px" }} /> 
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ margin: '10px' }}>
              <MDTypography gutterBottom>
              Şəxsiyyət vəsiqəsi
              </MDTypography>
              <img src={doc.identityCard} alt="image" style={{ maxWidth: "100%", maxHeight: "400px" }} /> 
            </div>
          </Grid>
      </Grid>
      <Grid container spacing={2}  style={{ marginTop: '30px', marginBottom: '20px' }}>
         <Grid item xs={12} sm={6} >
      <Card>
        <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
            <Icon fontSize="medium" color='success'>
              check_circle_outline
            </Icon>
          <MDBox textAlign="right" lineHeight={1.25}>
            <MDTypography variant="h6"  fontWeight='bold' color="text">
              Qəbul et
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox pb={2} px={2}>
        <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="ŞV seriya nömrəsi"
                      variant="standard"
                      placeholder="ŞV seriya nömrəsi"
                      fullWidth
                      value={Seria}
                      onChange={(e) => setSeria(e.target.value)}
                    />
                    </MDBox>
                    <MDBox mb={2}>
                    <MDButton
                        variant="contained"
                        color='success'
                        size="large"
                        style={{marginTop:'10px'}}
                        onClick={handleApproval}
                    >
                        Qəbul et
                    </MDButton>
                    </MDBox>
        </MDBox>
     
      </Card>
      </Grid>
         <Grid item xs={12} sm={6}>
      <Card>
        <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
            <Icon fontSize="medium" color='error'>
                cancel
            </Icon>
          <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography variant="h6"  fontWeight='bold' color="text">
              Rəd et
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox pb={2} px={2}>
        <MDBox mb={2}  mt={2} >
                    <MDTextarea
                    aria-label="minimum height"
                    minRows={5}
                    placeholder="Rədd etmə səbəbi"
                    style={{ width: '100%' }}
                    value={RejReason}
                    onChange={(e) => setRejReason(e.target.value)}
                    />
                    </MDBox>
                    <MDBox mb={2}>
                    <MDButton
                        variant="contained"
                        color='error'
                        size="large"
                        style={{marginTop:'10px'}}
                        onClick={handleReject}
                    >
                        Rəd et
                    </MDButton>
                    </MDBox>
        </MDBox>
        <MDSnackbar
                  color="success"
                  icon="check"
                  title="Uğurlu Əməliyyat"
                  content={message}
                  dateTime={new Date().toLocaleString().split(",")[0]}
                  open={successSB}
                  onClose={closeSuccessSB}
                  close={closeSuccessSB}
                  bgWhite
                />
        </Card>
        </Grid>
        </Grid>
    </>
    );
}
export default UserDocumentViewer;