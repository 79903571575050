import React, { useMemo, useCallback } from "react";
import MaterialReactTable from "material-react-table";
import { IconButton, Tooltip, Button,Toolbar} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Add, Delete, Edit, Visibility } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {useNavigate} from "react-router-dom";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import agent from "api/agent";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const Example = () => {
   const navigate = useNavigate();
  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryFn: async () => {
     const response = await agent.Slider.list('All');
      return response;
    },
    keepPreviousData: true,
  });

  const columns = useMemo(
    () => [
    {
        accessorKey: "cosmetologOnly",
        header: "Kosmetoloqlar üçün",
        id: "CosmetologOnly",
        Cell: ({ cell }) => {
          return cell.getValue() ? "Bəli" : "Xeyr";
        },
      },
      {
        header: "Image",
        accessorKey: "image",
        id: "image",
        Cell: ({ cell }) => (
          <img
            src={cell.getValue()}
            alt="Slider Image"
            style={{ width: "100px", height: "100px" }}
          />
        ),
      },
      {
        header: "Hədəf",
        accessorKey: "targetType",
        id: "TargetType",
      },
      {
        header: "Hədəf Adı",
        accessorKey: "targetName",
        id: "TargetName",
      },
    ],
    []
  );

  const handleDeleteRow = useCallback(
    async (id) => {
      if (
        !confirm(`Slideri silməyə əminsiniz?`)
      ) {
        return;
      }
       await agent.Slider.delete(id);
      refetch();
    },
    [refetch]
  );


  return (
    <MaterialReactTable
      columns={columns}
      data={data ?? []}
      manualFiltering = {true}
      manualPagination = {true}
      manualSorting = {true}
      enableRowActions
      initialState={{
        expanded: false,
        showColumnFilters: false,
      }}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      muiSelectCheckboxProps={{
        color: "secondary",
      }}
      renderRowActions={({ row, table }) => (
        <Toolbar>
          <IconButton onClick={() => handleDeleteRow(row.original.id)}>
            <Delete
              sx={{
                color: "error.main",
              }}
            />
          </IconButton>
        </Toolbar>
      )}
      renderTopToolbarCustomActions={({ table }) => {
        return (
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <Button
              color="error"
              onClick={() => navigate("/slider/add")}
              variant="contained"
            >
             Əlavə et
            </Button>
            <Tooltip arrow title="Refresh Data">
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      }}
         muiTablePaginationProps={{
          showFirstButton: false,
          showLastButton: false,
        }}
      state={{
        isLoading,
        showAlertBanner: isError,
        showProgressBars: isFetching,
      }}
    />
  );
};

const Sliders = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                 Slayderlər
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Sliders;
