// react-router components
import { Routes, Route, Navigate} from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

// Ibeauty Admin Dashboard React Context Provider
import {store} from "store/configureStore";
import {removeNotification} from "redux/globalNotificationSlice";

import React, {useEffect, useState} from 'react';

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import CircularProgress from '@mui/material/CircularProgress';

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import CompanyForm from "layouts/company/CompanyForm";
import BrandForm from "layouts/brand/BrandForm";
import ProductDetail from "layouts/product/ProductDetail";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";


// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController,setOpenConfigurator } from "context";

// import OneSignal from 'react-onesignal';

// Images
import brandWhite from "assets/images/logo-ct.jpg";
import brandDark from "assets/images/logo-ct-dark.jpg";
import ProductForm from "layouts/product/ProductForm";
import Login from "layouts/authentication/sign-in";

import { useAppDispatch } from "store/configureStore";
import { setToken } from "redux/accountSlice";
import {PrivateRoute}  from "layouts/authentication/PrivateRoute";

import MDSnackbar from "components/MDSnackbar";
import CategoryForm from "layouts/category/CategoryForm";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const [loading,setLoading] = useState(false);
  const dispatchAP = useAppDispatch();

  const [notification , setNotification] = useState({});

 useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const notification = store.getState().globalNotification.notification
      if(Object.keys(notification).length > 0) {
        setNotification(notification);
      }else{
        setNotification({});
      }
    });
    return () => {
      unsubscribe();
    }
  },[]);
  

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setLoading(true);
      return;
    }
    dispatchAP(setToken(JSON.parse(token)));
    setLoading(true);

    // OneSignal.init({
    //   appId: "f23defd2-18cb-46e4-9fa3-d6fd650ecd6d"
    // });
  }, []);
  const {
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

 let  RouteArray = [];
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if(route.items && route.items.length > 0) {
         route.items.map((item) => {
         RouteArray.push(<Route exact path={item.route} element={<PrivateRoute>{item.component}</PrivateRoute>} key={item.key} />);
      })}
      else{
         RouteArray.push(<Route exact path={route.route} element={<PrivateRoute>{route.component}</PrivateRoute>} key={route.key} />);
      }
      return RouteArray.length > 0 ? RouteArray : <React.Fragment></React.Fragment>;
    });


  if(!loading) {  
    return <div>Loading...</div>;
  }
 const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <PrivateRoute>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName="IBU Beauty"
                routes={routes}
              />
              <Configurator />
              {layout === "vr" && <Configurator />}
            </PrivateRoute>
          </>
        )}
        <Routes>
          {getRoutes(routes)}
          <Route
            exact
            path="*"
            element={
              <PrivateRoute>
                <Navigate to="/dashboard" />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <PrivateRoute>
                <Login />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/company/edit"
            element={
              <PrivateRoute>
                <CompanyForm type="edit" />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/brand/edit"
            element={
              <PrivateRoute>
                <BrandForm type="edit" />
              </PrivateRoute>
            }
          />
         <Route
            exact
            path="/category/edit"
            element={
              <PrivateRoute>
                <CategoryForm type="edit" />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/category/add-products"
            element={
              <PrivateRoute>
                <CategoryForm type="add-products"/>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/product/detail"
            element={
              <PrivateRoute>
                <ProductDetail />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/product/edit"
            element={
              <PrivateRoute>
                <ProductForm type="edit" />
              </PrivateRoute>
            }
          />
        </Routes>
        <CircularProgress
          variant="indeterminate"
          size={120}
          thickness={3}
          color="primary"
          sx={{
            display: store.getState().globalNotification.loadingAction ? "block" : "none",
            visibility: store.getState().globalNotification.loadingAction ? "visible" : "hidden",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-50px",
            marginLeft: "-50px",
            zIndex: 9999,
          }}
        />
      <MDSnackbar
      color={
        Object.keys(notification).length > 0 ? notification.type : "info"
      }
      icon="check"
      title={
        Object.keys(notification).length > 0 ? notification.title : "Notification"
      }
      content={
        Object.keys(notification).length > 0 ? notification.message : "Hello, world! This is a notification message"
      }
      dateTime={new Date().toLocaleString()}
      open={
       Object.keys(notification).length > 0 
      }
      onClose={() => store.dispatch(removeNotification())}
      close={() => store.dispatch(removeNotification())}
      bgWhite
     />
      </ThemeProvider>
    </QueryClientProvider>
  );
}
