import React, { useMemo} from "react";
import MaterialReactTable from "material-react-table";
import { Box, Stack } from "@mui/material";
import { IconButton, Tooltip, Button, Toolbar, Typography, } from "@mui/material";
import { Add, Delete, Edit, Visibility } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import {useNavigate } from "react-router-dom";


import agent from "api/agent";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const Example = () => {
  const navigate = useNavigate();
  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryFn: async () => {
      return await agent.Category.Get()
    },
    keepPreviousData: true,
  });

  const dateOptions =  { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit', 
    hour12: false 
};
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.categoryLanguages}`, 
        id: "name", 
        header: "Kateqoriya",
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
           {
            row.original.photoUrl ? (
              <img
                src={row.original.photoUrl}
                alt="category photo"
                width={100}
                height={200}
                style={{
                  width: "2.5rem",
                  height: "2.5rem",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <AccountCircle
                sx={{
                  width: "2.5rem",
                  height: "2.5rem",
                  borderRadius: "50%",
                  fontSize: "2.5rem",
                  fontWeight: 500,
                  
                }}
              />
            )
           }
           <Typography variant='inherit'>
            {row.original.categoryLanguages && row.original.categoryLanguages.length > 0 
            ? (row.original.categoryLanguages.find(lang => lang.languageCode === 'az-AZ')?.name || 'Default Name')
             : 'Default Name'}
            </Typography>
          </Box>
        ),
      },
      {
        header: "Kosmetoloqa Özəl",
        id: "cosmetologOnly",
        Cell: ({ cell , row }) => {
          return row.original.cosmetologOnly ?  
            <Typography  variant='overline' align='center'>Bəli</Typography> 
          : <Typography variant='overline' align='center'>Xeyir</Typography>;
        },
      },
      {
        id: "created",
        header: "Əlavə Edilmə Tarixi",
        Cell: ({ cell }) => new Intl.DateTimeFormat('Latin', dateOptions).format(cell.getValue()), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, 
      },
      {
        id: "actions",
        header: "Əməliyyatlar",
        Cell: ({ row }) => (
          <Tooltip>
            <IconButton
              title="Sil"
              color="error"
              onClick={() => handleRemoveCategory(row.original.id)}
            >
              <Delete />
            </IconButton>
            <IconButton
              title="Düzəliş Et"
              color="warning"
              onClick={() => {
                navigate("/category/edit", {
                  state: {
                      id: row.original.id,
                      cosmetologOnly: row.original.cosmetologOnly,
                      photo: row.original.photoUrl,
                      categoryLanguages: row.original.categoryLanguages
                  }
              }
            )}}
            >
              <Edit/>
            </IconButton>
            <IconButton
              title="Yeni Məhsul Əlavə Et"
              color="warning"
              onClick={() => {
                navigate("/category/add-products", {
                  state: {
                      id: row.original.id,
                  }
              }
            )}}
            >
              <Add/>
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    []
  );


const handleRemoveCategory = async (categoryId) => {
      await agent.Category.Remove(categoryId);
      refetch();
  };

const handleEditCategory = async (productId) => {
      alert('Tezlikle')
 };
  
const handleRemoveProduct = async (value) => {
      const response = await agent.Category.RemoveProduct(value);
      refetch();
};
 
const handleAddProduct = async (categoryId) => {
      alert('Tezlikle')
};


  return (
    <MaterialReactTable
      columns={columns}
      data={data ?? []} 
      enablePagination={false}
      enableColumnFilterModes
      enableColumnResizing
      filterFromLeafRows 
      initialState={{
        expanded: false,
        pagination:false,
        
      }}
      editingMode="modal" 
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      renderDetailPanel={({ row }) => (
        <Box
          sx={{
            display: "grid",
            margin: "auto",
            gridTemplateColumns: "1fr 1fr",
            width: "100%",
          }}
        >
          <Stack spacing={2}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="h6" color="info">
                Məhsulun adı
              </MDTypography>
              <MDTypography variant="h6" color="info">
                Məhsulun aid olduğu brand
              </MDTypography>
              <MDTypography color="white">Əməliyyatlar</MDTypography>
            </Box>
            {row.original?.products?.map((product) => (
              <Box key={product.id} sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6">{product.name}</MDTypography>
                <MDTypography variant="h6">{product.brand}</MDTypography>
                <MDButton
                  variant="outlined"
                  color="error"
                  onClick={() =>  handleRemoveProduct({
                    categoryId: row.original.id,
                    productId: product.id
                  })}
                >
                  Sil
                </MDButton>
              </Box>
            ))}
          </Stack>
        </Box>
      )}
      muiSelectCheckboxProps={{
        color: "secondary",
      }}
      state={{
        isLoading,
        showAlertBanner: isError,
        showProgressBars: isFetching,
      }}
    />
  );
};

const Categories = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Kateqoriyalar
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Categories;
