/** 
  All of the routes for the Ibeauty Admin Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Ibeauty Admin Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Companies from "layouts/company";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import Brands from "layouts/brand";
import Customers from "layouts/users/Customers";
import Cosmetologist from "layouts/users/Cosmetologist";
import Products from "layouts/product";
import ApprovalRequests from "layouts/users/ApprovalRequests";
// @mui icons
import Icon from "@mui/material/Icon";
import CompanyForm from "layouts/company/CompanyForm";
import BrandForm from "layouts/brand/BrandForm";
import ProductForm from "layouts/product/ProductForm";
import SliderForm from "layouts/slider/SliderForm";
import Sliders from "layouts/slider";
import MapSettings from "layouts/MapSettings/PermittedArea";
import Orders from "layouts/orders";
import Coupons from "layouts/coupons";
import Discounts from "layouts/discounts";
import DiscountForm from "layouts/discounts/DiscountForm";
import TopHighlight from "layouts/tophighlight";
import AppVersionManagment from "layouts/AppVersionManagment";
import Couriers from "layouts/couriers";
import SendNotification from "layouts/notifications/SendNotification";
import PartnerCompanyEmployees from "layouts/users/PartnerCompanyEmployees"

import Category from "layouts/category";
import CategoryForm from "layouts/category/CategoryForm";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Önə Çıxanlar",
    key: "topHighlight",
    icon: <Icon fontSize="small">star</Icon>,
    route: "/topHighlight",
    component: <TopHighlight/>,
  },
  {
    type: "collapse",
    name: "Sifarişlər",
    key: "order",
    icon: <Icon fontSize="small">inventory_2</Icon>,
    items: [
      {
        type: "item",
        name: "Yeni Sifarişlər",
        key: "new-orders",
        route: "/orders",
        icon: <Icon fontSize="small">add_circle</Icon>,
        component: <Orders/>,
      },
    ],
  },
  {
    type: "collapse",
    name: "Kuponlar",
    key: "coupons",
    icon: <Icon fontSize="small">local_offer</Icon>,
    route: "/coupons",
    component: <Coupons />,
  },
  {
    type: "collapse",
    name: "Endirimlər",
    key: "discount",
    icon: <Icon fontSize="small">percent</Icon>,
    items: [
      {
        type: "item",
        name: "Endirim Əlavə Et",
        key: "add-discount",
        route: "/discount/add",
        icon: <Icon fontSize="small">add_circle</Icon>,
        component: <DiscountForm type="create" />,
      },
      {
        type: "item",
        name: "Bütün Endirimlər",
        key: "discounts",
        route: "/discount/discounts",
        // list icon
        icon: <Icon fontSize="small">list</Icon>,
        component: <Discounts />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Şirkətlər",
    key: "company",
    icon: <Icon fontSize="small">business</Icon>,
    items: [
      {
        type: "item",
        name: "Şirkət Əlavə Et",
        key: "add-company",
        route: "/company/add",
        icon: <Icon fontSize="small">add_circle</Icon>,
        component: <CompanyForm type="create" />,
      },
      {
        type: "item",
        name: "Bütün Şirkətlər",
        key: "companies",
        route: "/company/companies",
        icon: <Icon fontSize="small">list</Icon>,
        component: <Companies />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Brendlər",
    key: "brand",
    icon: <Icon fontSize="small">collections</Icon>,
    items: [
      {
        type: "item",
        name: "Brend Əlavə Et",
        key: "add-brand",
        route: "/brand/add",
        icon: <Icon fontSize="small">add_circle</Icon>,
        component: <BrandForm type="create" />,
      },
      {
        type: "item",
        name: "Bütün Brendlər",
        key: "brends",
        route: "/brand/brands",
        // list icon
        icon: <Icon fontSize="small">list</Icon>,
        component: <Brands />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Kateqoriya",
    key: "catgory",
    icon: <Icon fontSize="small">category</Icon>,
    items: [
      {
        type: "item",
        name: "Kateqoriya Əlavə Et",
        key: "add-category",
        route: "/category/add",
        icon: <Icon fontSize="small">add_circle</Icon>,
        component: <CategoryForm type="create" />,
      },
      {
        type: "item",
        name: "Bütün Kateqoriyalar",
        key: "categories",
        route: "/category/categories",
        icon: <Icon fontSize="small">list</Icon>,
        component: <Category />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Məhsullar",
    key: "product",
    icon: <Icon fontSize="small">local_mall</Icon>,
    items: [
      {
        type: "item",
        name: "Məhsul Əlavə Et",
        key: "add-product",
        route: "/product/add",
        icon: <Icon fontSize="small">add_circle</Icon>,
        component: <ProductForm type="create" />,
      },
      {
        type: "item",
        name: "Bütün Məhsullar",
        key: "products",
        route: "/product/products",
        // list icon
        icon: <Icon fontSize="small">list</Icon>,
        component: <Products />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Slayder",
    key: "slider",
    icon: <Icon fontSize="small">business</Icon>,
    items: [
      {
        type: "item",
        name: "Slayder Əlavə Et",
        key: "add-slider",
        route: "/slider/add",
        icon: <Icon fontSize="small">add_circle</Icon>,
        component: <SliderForm type="create" />,
      },
      {
        type: "item",
        name: "Bütün Slayderlər",
        key: "sliders",
        route: "/slider/sliders",
        icon: <Icon fontSize="small">list</Icon>,
        component: <Sliders />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Partnyor Şirkət Əməkdaşları",
    key: "partner-company-employees",
    route: "/partner-company-employees",
    icon: <Icon fontSize="small">badge</Icon>,
    component: <PartnerCompanyEmployees />,
   },
  {
    type: "collapse",
    name: "İstifadəçilər",
    key: "user",
    icon: <Icon fontSize="small">people</Icon>,
    items: [
      {
        type: "item",
        name: "Kosmetoloqlar",
        key: "cosmetologists",
        route: "cosmetologists",
        icon: <Icon fontSize="small">format_list_numbered</Icon>,
        component: <Cosmetologist />,
      },
      {
        type: "item",
        name: "Müştərilər",
        key: "customers",
        route: "/user/customers",
        // list icon
        icon: <Icon fontSize="small">format_list_numbered</Icon>,
        component: <Customers />,
      },
      {
        type: "item",
        name: "Təsdiq sorğuları",
        key: "user-requests",
        route: "/user/user-requests",
        // list icon
        icon: <Icon fontSize="small">document_scanner</Icon>,
        component: <ApprovalRequests />,
      },
    ],
  },
   {
    type: "collapse",
    name: "Kuryerlər",
    key: "couriers",
    route: "/couriers",
    icon: <Icon fontSize="small">person_apron</Icon>,
    component: <Couriers />
   },
   {
    type: "collapse",
    name: "Bildirişlər",
    key: "notification",
    icon: <Icon fontSize="small">notifications</Icon>,
    items: [
      {
        type: "item",
        name: "Bildiriş Göndər",
        key: "send_notification",
        route: "notification/send",
        icon: <Icon fontSize="small">send</Icon>,
        component: <SendNotification/>
      },
    ],
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Parametrlər",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    items: [
      {
        type: "item",
        name: "Xəritə Parametrləri",
        route: "/settings/map",
        icon: <Icon fontSize="small">location_on</Icon>,
        component: <MapSettings />,
      },
      {
        type: "item",
        name: "Tətbiq Versiyasının İdarə Edilməsi",
        route: "/settings/app-version",
        icon: <Icon fontSize="small">system_update</Icon>,
        component: <AppVersionManagment/>,
      }
    ],
  },
  // {
  //   type: "title",
  //   name: "Authentication",
  //   key: "authentication",
  //   title: "Bura Adiii Sozduuu",
  // },
  {
    type: "divider",
    name: "Divider",
    key: "divider",
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
];

export default routes;
