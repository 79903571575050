import {createSlice} from "@reduxjs/toolkit";

const initialState = {
   approval: [],
   customers: [],
   cosmetologist: [],
   brand: [],
   companies: [],
   products: [],
};

export const tableDataSlice = createSlice({
    name: "tableData",
    initialState,
    reducers: {
        setRows: (state, action) => {
           switch(action.payload.type){
                case "approval":
                    state.approval = action.payload.data;
                    break;
                case "customers":
                    state.customers = action.payload.data;
                    break;
                case "cosmetologist":
                    state.cosmetologist = action.payload.data;
                    break;
                case "brand":
                    state.brand = action.payload.data;
                    break;
                case "companies":
                    state.companies = action.payload.data;
                    break;
                case "products":
                    state.products = action.payload.data;
                default:
                    break;
              }
        },
        removeRow: (state, action) => {
            state.rows = state.rows.filter((row) => row.userId !== action.payload);
        },
    },
});

export const { setRows, removeRow } = tableDataSlice.actions;
export default tableDataSlice.reducer;


