import React, { useMemo, useState, useCallback, useEffect } from "react";
import MaterialReactTable from "material-react-table";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Edit, Visibility, Add } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";

// Agent import
import agent from "api/agent";

import {
  IconButton,
  Tooltip,
  Toolbar,
  Box,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";

// Formik and Yup validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import SelectBox from "components/MDSelectBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import axios from "axios";

// const queryClient = new QueryClient();
const Example = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [highlightId , setHighlightId] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL("api/TopHighlight/top-highlights", "https://api.ibu.az/");
      fetchURL.searchParams.set("start", `${pagination.pageIndex + 1}`);
      fetchURL.searchParams.set("size", `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      const response =  await agent.TopHighlight.list(fetchURL.search);
      return response;
    },
    keepPreviousData: true,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        id: "Name",
        header: "Ad",
      },
      {
        accessorKey: "isActive",
        header: "Aktiv",
        id: "IsActive",
        size: 200,
        Cell: ({ row }) => {
          return (
            <MDBox>
              <MDTypography variant="body2" fontWeight="bold">
                {row.original.isActive ? "Aktiv" : "Deaktiv"}
              </MDTypography>
            </MDBox>
          );
        },
      },
      {
        accessorKey: "highlightType",
        header: "Növü",
        id: "HighlightType",
      },
      {
        accessorKey: "count",
        header: "Top Sayı",
        id: "Count",
      },
      {
        accessorKey: "timeRangeInDays",
        header: "Vaxt Aralığı",
        id: "TimeRangeInDays",
      },
      {
        accessorKey: "includeRemaining",
        header: "Qalanları da göstər",
        id: "IncludeRemaining",
        Cell: ({ row }) => {
            return (
                <MDBox>
                <MDTypography variant="body2" fontWeight="bold">
                    {row.original.includeRemaining ? "Bəli" : "Xeyr"}
                </MDTypography>
                </MDBox>
            );
            }
      },
    ],
    []
  );

  const handleDeleteRow = useCallback(
    async (table) => {
      if (
        !confirm(`Bu ${table.getSelectedRowModel().flatRows.length} endirimi silməyə əminsiniz?`)
      ) {
        return;
      }
      let selected = table.getSelectedRowModel().flatRows.map((row) => {
        return row.original.id;
      });
    try {
      await agent.TopHighlight.delete(selected);
      refetch()
    } catch (error) {
      
    }
    },
    [refetch]
  );

    const rowsPerPageOptions = [];
    for (let i = 10; i <= data?.totalCount; i += 10) {
      rowsPerPageOptions.push(i);
    }
    rowsPerPageOptions.push(data?.totalCount);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data?.items ?? []}
        initialState={{ showColumnFilters: true }}
        manualFiltering
        manualPagination
        manualSorting
        enableRowActions
        enableRowSelection
        muiSelectCheckboxProps={{
          color: "secondary",
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderRowActions={({ row }) => (
          <Toolbar>
            <Button
              color="info"
              onClick={() => {
                setHighlightId(row.original.id);
                agent.TopHighlight.activeDeactive({
                  highlightType: row.original.highlightType,
                  topHighlightId: row.original.id,
                  isActive: !row.original.isActive,
                })
                  .then((response) => {
                    console.log(response);
                    refetch();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
              variant="contained"
            >
              {row.original.isActive ? "Deaktiv" : "Aktiv"}
            </Button>
          </Toolbar>
        )}
        renderTopToolbarCustomActions={({ table }) => {
          return (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <Button
                color="error"
                sx={{
                  color: "white",
                  backgroundColor: "red",
                }}
                disabled={table.getSelectedRowModel().flatRows.length < 1}
                onClick={()=> handleDeleteRow(table)}
                variant="contained"
              >
                Silmək
              </Button>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              <Button
                color="info"
                onClick={() => {
                  setAddModalOpen(true);
                }}
                variant="contained"
              >
                Əlavə et
              </Button>
            </div>
          );
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: rowsPerPageOptions,
          showFirstButton: true,
          showLastButton: true,
        }}
        rowCount={data?.totalCount ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
      <CreateCouponCodeModal open={addModalOpen} onClose={() => setAddModalOpen(false)} refetch={refetch}/>
    </>
  );
};

const CreateCouponCodeModal = ({ open, onClose ,refetch}) => {
  const handleSubmit = (values) => {
    agent.TopHighlight.add(values)
      .then((response) => {
        refetch()
        onClose();
      })
      .catch((error) => {
        console.log(error);
      });
    
  };
  const initialValues = {
    nameAz:"",
    nameRu:"",
    nameEn:"",
    nameTr:"",
    count:1,
    timeRangeInDays:1,
    includeRemaining:false,
    highlightType:"",
  };

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form>
          <Dialog open={open}>
            <DialogTitle textAlign="center">
              <MDTypography variant="h6">Əlavə et</MDTypography>
            </DialogTitle>
            <DialogContent>
              <Stack
                sx={{
                  width: "100%",
                  minWidth: { xs: "300px", sm: "360px", md: "400px" },
                  gap: "1.5rem",
                }}
              >
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <Field
                      type="text"
                      name="nameAz"
                      id="nameaz"
                      label="Ad Azerbyancanca"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                     <Field
                      type="text"
                      name="NameRu"
                      id="nameru"
                      label="Ad Rusca"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                     <Field
                      type="text"
                      name="nameEn"
                      id="nameEn"
                      label="Ad İngiliscə"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                     <Field
                      type="text"
                      name="nameTr"
                      id="nameTr"
                      label="Ad Türkcə"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <Field
                        type="number"
                        name="count"
                        id="count"
                        label="Top Sayı"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.count}
                        as={MDInput}
                        variant="standard"
                        fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <Field
                      component={SelectBox}
                      name="timeRangeInDays"
                      label="Vaxt Aralığı"
                      options={[
                        { value: 1, label: "1 gün" },
                        { value: 2, label: "2 gün" },
                        { value: 3, label: "3 gün" },
                        { value: 4, label: "4 gün" },
                        { value: 5, label: "5 gün" },
                        { value: 6, label: "6 gün" },
                        { value: 7, label: "7 gün" },
                        { value: 8, label: "8 gün" },
                        { value: 9, label: "9 gün" },
                        { value: 10, label: "10 gün" },
                        { value: 11, label: "11 gün" },
                        { value: 12, label: "12 gün" },
                        { value: 13, label: "13 gün" },
                        { value: 14, label: "14 gün" },
                        { value: 15, label: "15 gün" },
                        { value: 16, label: "16 gün" },
                        { value: 17, label: "17 gün" },
                        { value: 18, label: "18 gün" },
                        { value: 19, label: "19 gün" },
                        { value: 20, label: "20 gün" },
                        { value: 21, label: "21 gün" },
                        { value: 22, label: "22 gün" },
                        { value: 23, label: "23 gün" },
                        { value: 24, label: "24 gün" },
                        { value: 25, label: "25 gün" },
                        { value: 26, label: "26 gün" },
                        { value: 27, label: "27 gün" },
                        { value: 28, label: "28 gün" },
                        { value: 29, label: "29 gün" },
                        { value: 30, label: "30 gün" },
                        { value: 60, label: "2 Ay" },
                        { value: 90, label: "3 Ay" },
                        { value: 120, label: "4 Ay" },
                        { value: 150, label: "5 Ay" },
                        { value: 180, label: "6 Ay" },
                        { value: 210, label: "7 Ay" },
                        { value: 240, label: "8 Ay" },
                        { value: 270, label: "9 Ay" },
                        { value: 300, label: "10 Ay" },
                        { value: 330, label: "11 Ay" },
                        { value: 360, label: "12 Ay" },
                      ]}
                      fullWidth
                      required
                      onChange={(value) => setFieldValue("timeRangeInDays", value)}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <Field
                      name="highlightType"
                      label="Növü"
                      component={SelectBox}
                      options={[
                        { value: "Product", label: "Top Məhsullar" },
                        { value: "Cosmetologist", label: "Top Kosmetoloqlar" },
                        { value: "Customer", label: "Top Müştərilər" },
                        { value: "Company", label: "Top Şirkətlər" },
                        { value: "Brand", label: "Top Brendlər" },
                      ]}
                      onChange={(value) => setFieldValue("highlightType", value)}
                    />
                    {touched.highlightType &&
                      errors.highlightType &&
                      errorMessages(errors.highlightType)}
                  </MDBox>
                  <MDBox mb={2}></MDBox>
                </Grid>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ p: "1.25rem" }}>
              <Button onClick={onClose}>Ləğv Et</Button>
              <Button color="info" onClick={handleSubmit} variant="contained">
                Əlavə Et
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};


const TopHighlight = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Önə çıxanlar
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default TopHighlight;
