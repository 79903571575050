export const formatDateAz = (datetime) => {
    let date = new Date(datetime);
    let options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    return date.toLocaleString('az-AZ', options);
  };