import { useState } from "react";
import * as React from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
//Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSwitch from "components/MDSwitch";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import agent from "api/agent";

function LanguageForm({ languageCheckboxes, activeTab, handleTabChange }) {
  // Başlangıç değerlerini oluştur
  const initialValues = languageCheckboxes.reduce((acc, checkbox) => {
    return {
      ...acc,
      [`title${checkbox.name}`]: "",
      [`message${checkbox.name}`]: "",
      stayVisible: false
    };
  }, {});

  const validationSchema = Yup.object().shape({
    titleEnglish: Yup.string().required("İngilis dilində başlıq vacibdir."),
    messageEnglish: Yup.string().required("İngilis dilində mesaj vacibdir."),
    // Diğer diller için şema
    titleAzerbaijani: Yup.string(),
    messageAzerbaijani: Yup.string().when('titleAzerbaijani', {
      is: (val) => !!val,
      then: Yup.string().required("Mesaj vacibdir."),
      otherwise: Yup.string()
    }),
    titleTurkish: Yup.string(),
    messageTurkish: Yup.string().when('titleTurkish', {
      is: (val) => !!val,
      then: Yup.string().required("Mesaj vacibdir."),
      otherwise: Yup.string()
    }),
    titleRussian: Yup.string(),
    messageRussian: Yup.string().when('titleRussian', {
      is: (val) => !!val,
      then: Yup.string().required("Mesaj vacibdir."),
      otherwise: Yup.string()
    })
  });
  
  // Form submit işlemi
  const handleSubmit = async (values, { setSubmitting , resetForm }) => {
     try{
          setSubmitting(true)
          await agent.PushNotification.Send(values);
          resetForm();
     }catch{
           
     }finally{
      setSubmitting(false)
     }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, handleChange, handleBlur, isSubmitting,resetForm }) => (
        <Form>
          <TabContext value={activeTab}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              {languageCheckboxes.map((checkbox, index) => (
                <Tab key={index} label={checkbox.name} />
              ))}
            </TabList>
            {languageCheckboxes.map((checkbox, index) => (
              <TabPanel value={index} key={index}>
                <MDBox mt={2}>
                  <MDTypography variant="h6">Forum in {checkbox.name}:</MDTypography>
                  <MDBox mb={2}>
                    <Field
                      type="text"
                      name={`title${checkbox.name}`}
                      id={`title${checkbox.name}`}
                      label={`Başlığ ${checkbox.name} dilində`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[`title${checkbox.name}`]}
                      component={MDInput}
                      variant="standard"
                      fullWidth
                    />
                    <ErrorMessage name={`title${checkbox.name}`} component="div" className="error" />
                  </MDBox>
                  <MDBox mb={2}>
                    <Field
                      label={`Mesaj ${checkbox.name} dilində`}
                      name={`message${checkbox.name}`}
                      id={`message${checkbox.name}`}
                      component={MDInput}
                      variant="standard"
                      fullWidth
                      multiline
                      rows={4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[`message${checkbox.name}`]}
                    />
                    <ErrorMessage name={`message${checkbox.name}`} component="div" className="error" color="red" />
                  </MDBox>
                </MDBox>
              </TabPanel>
            ))}
          </TabContext>
          <MDBox color="red" mt={4} mb={1}>
            <Field
              component={MDSwitch}
              name="stayVisible"
              id="stayVisible"
              checked={values.stayVisible}
              onChange={handleChange}
            />
            <label htmlFor="stayVisible">
              <span style={{ color: "#000" }}>Tətbiqin bildirişlər bölümündə əks olunsun: </span>
              <span style={{ color: values.stayVisible ? "blue" : "red" }}>
                {values.stayVisible ? "Bəli" : "Xeyir"}
              </span>
            </label>
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton
              type="submit"
              variant="gradient"
              color="info"
              fullWidth
              disabled={isSubmitting}
            >
              Göndər
            </MDButton>
          </MDBox>
        </Form>
      )}
    </Formik>
  );
}

function SendNotification() {
  const [activeTab, setActiveTab] = useState(0);
  const [languageCheckboxes, setLanguageCheckboxes] = useState([
    { name: "English", checked: true, disabled: true },
    { name: "Azerbaijani", checked: false },
    { name: "Turkish", checked: false },
    { name: "Russian", checked: false },
  ]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <DashboardLayout>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant="h5">Bildiriş Göndər</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2}>
                <LanguageForm 
                  languageCheckboxes={languageCheckboxes}
                  activeTab={activeTab}
                  handleTabChange={handleTabChange}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default SendNotification;
