import React, { useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { useQuery } from "@tanstack/react-query";
import { IconButton, Tooltip, Box, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AccountCircle from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import BadgeIcon from "@mui/icons-material/Badge";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import FaceIcon from "@mui/icons-material/Face";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import agent from "api/agent";

const EmployeeTable = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: ["employee-data", pagination.pageIndex, pagination.pageSize],
    queryFn: async () => {
      const response = await agent.Employee.All(
        pagination.pageIndex + 1,
        pagination.pageSize
      );
      return response;
    },
    keepPreviousData: true,
  });

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.nameSurname}`,
        id: "NameSurname",
        header: (
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
            <FaceIcon />
            <Typography variant="subtitle2">Ad Soyad</Typography>
          </Box>
        ),
        size: 120,
        Cell: ({ renderedCellValue, row }) => (
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
            {row.original.profilePhotoUrl ? (
              <img
                src={row.original.profilePhotoUrl}
                alt="profile"
                style={{
                  width: "1.8rem",
                  height: "1.8rem",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <AccountCircle
                sx={{ width: "1.8rem", height: "1.8rem", borderRadius: "50%" }}
              />
            )}
            <Typography variant="body2">{renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "referralCode",
        id: "referralCode",
        header: (
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
            <BadgeIcon />
            <Typography variant="subtitle2">ID</Typography>
          </Box>
        ),
        size: 80,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={{
              color: "#fff",
              backgroundColor: "#4caf50",
              borderRadius: "4px",
              padding: "0.2rem 0.4rem",
              fontSize: "0.75rem",
            }}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "companyName",
        id: "companyName",
        header: (
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
            <BusinessIcon />
            <Typography variant="subtitle2">Şirkət</Typography>
          </Box>
        ),
        size: 100,
        Cell: ({ cell }) => (
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {cell.getValue()}
          </Typography>
        ),
      },
      {
        accessorKey: "positionName",
        id: "positionName",
        header: (
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
            <ContactMailIcon />
            <Typography variant="subtitle2">Vəzifə</Typography>
          </Box>
        ),
        size: 100,
        Cell: ({ cell }) => (
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {cell.getValue()}
          </Typography>
        ),
      },
      {
        accessorKey: "created",
        id: "created",
        header: (
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
            <CalendarTodayIcon />
            <Typography variant="subtitle2">Əlavə Edilmə tarixi</Typography>
          </Box>
        ),
        size: 120,
        Cell: ({ cell }) => (
          <Typography variant="body2">{new Date(cell.getValue()).toLocaleDateString()}</Typography>
        ),
      },
      {
        accessorKey: "createdBy",
        id: "createdBy",
        header: (
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
            <PersonIcon />
            <Typography variant="subtitle2">Əlavə Edən</Typography>
          </Box>
        ),
        size: 100,
        Cell: ({ cell }) => (
          <Typography variant="body2">{cell.getValue()}</Typography>
        ),
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data?.items ?? []}
      manualPagination
      manualSorting
      enableRowActions
      onPaginationChange={setPagination}
      renderTopToolbarCustomActions={() => (
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      )}
      muiTablePaginationProps={{
        rowsPerPageOptions: [10, 20, 50, data?.totalCount || 100],
        showFirstButton: true,
        showLastButton: true,
      }}
      rowCount={data?.totalCount ?? 0}
      state={{
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isFetching,
      }}
    />
  );
};

const Employees = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={2}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Kosmetoloqlar
              </MDTypography>
            </MDBox>
            <MDBox pt={2}>
              <EmployeeTable />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Employees;