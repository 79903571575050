import React, { useMemo, useState, useCallback } from "react";
import MaterialReactTable from "material-react-table";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Edit, Visibility, Add, Delete } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";

// react-router-dom components
import {useNavigate } from "react-router-dom";

// Agent import
import agent from "api/agent";

import {
  IconButton,
  Tooltip,
  Toolbar,
  Box,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";

// Formik and Yup validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";


const Example = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enableOrdering, setEnableOrdering] = useState(false);
  const [addCouponCodeModalOpen, setAddCouponCodeModalOpen] = useState(false);
  const [brandId, setBrandId] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const closeSuccessSB = () => {
    setSuccessSB(false);
    setErrorSB(false);
  };

  const { data, isError, isFetching, isLoading, refetch  } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        "api/Brand/GetAllBrands",
        "https://api.ibu.az/"
      );
      fetchURL.searchParams.set('start',`${pagination.pageIndex + 1}`);
      fetchURL.searchParams.set('size', `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      const response = await agent.Brand.getAllBrands(fetchURL.search);
       setTableData(response.items);
      return response;
    },
    keepPreviousData: true,
  });

  const updateDisplayOrder = async () => {
     setLoading(true);
    const data = {
      companyId: tableData[0].companyId,
      brandDisplayOrders: {}
    };
    tableData.forEach((item, index) => {
      data.brandDisplayOrders[item.id] = index + 1;
    });
    try {
      await agent.Brand.updateDisplayOrder(data);
      setSuccessSB(true);
    }
    catch (error) {
      setLoading(false);
      setErrorSB(true);
    }
    finally {
      setLoading(false);
    }
  };

  

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, //accessorFn used to join multiple data into a single cell
        id: "Name", //id is still required when using accessorFn instead of accessorKey
        header: "Ad",
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              alt="avatar"
              height={50}
              src={row.original.logo}
              loading="lazy"
              style={{ borderRadius: "50%" }}
            />
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorFn: (row) => `${row.copmanyName}`, //accessorFn used to join multiple data into a single cell
        id: "Company.Name", //id is still required when using accessorFn instead of accessorKey
        header: "Şirkət adı",
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              alt="avatar"
              height={50}
              src={row.original.companyLogo}
              loading="lazy"
              style={{ borderRadius: "50%" }}
            />
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: "cosmetologOnly",
        header: "Kosmetoloqlar üçün",
        id: "CosmetologOnly",
        Cell: ({ cell }) => {
          return cell.getValue() ? "Bəli" : "Xeyr";
        },
      },
      {
        accessorKey: "productCount",
        header: "Məhsul sayı",
        id: "ProductCount",
        columnFilterModeOptions: ["between", "greaterThan", "lessThan"],
        filterFn: "between",
      },
    ],
    []
  );

  const handleDeleteRow = useCallback(
    async (ids) => {
      if (
        !confirm(
          `${ids.length} qədər brand silmək istədiyinizə əminsiniz?`
        )
      ) {
        return;
      }
       try {
          await agent.Brand.delete(ids);
          refetch();
        } catch (error) {
          console.log(error);
        }
    },
    [refetch]
  );

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      tableData[row.index] = values;
      //send/receive api updates here, then refetch or update local table data for re-render
      setTableData([...tableData]);
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

    const rowsPerPageOptions = [];
    for (let i = 10; i <= data?.totalCount; i += 10) {
      rowsPerPageOptions.push(i);
    }
    rowsPerPageOptions.push(data?.totalCount);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        initialState={{
           showColumnFilters: true,
          //  grouping: ['CompanyName'],
           }}
        enableGrouping
        enableRowOrdering={true}
        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.items?.splice(
                (hoveredRow).index,
                0,
                data.items.splice(draggingRow.index, 1)[0],
              );
              setTableData([...data.items]);
              setEnableOrdering(true);
            }
          },
        })}
        manualFiltering
        manualPagination
        manualSorting
        enableRowActions
        enableSelectAll
        enableRowSelection
        enableColumnFilterModes
        editingMode="modal" //default
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderRowActions={({ row, table }) => (
          <Toolbar>
            <IconButton 
              onClick={() => {
                navigate("/brand/edit", {
                  state: {
                    id: row.original.id,
                    name: row.original.name,
                    companyId: row.original.companyId,
                    companyName: row.original.companyName,
                    logo: row.original.logo,
                    cosmetologOnly: row.original.cosmetologOnly
                  },
              }
            )}}
             >
              <Edit
                sx={{
                  color: "orange",
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                setbrandId(row.original.id);
                setAddCouponCodeModalOpen(true);
              }}
            >
              <Add
                sx={{
                  color: "blue",
                }}
              />
            </IconButton>
          </Toolbar>
        )}
        renderTopToolbarCustomActions={({ table }) => {
          const handleDeactivate = () => {
            const ids = table.getSelectedRowModel().flatRows.map((item)=>{
              return item.original.id;
            })
            handleDeleteRow(ids);
          };

          return (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <MDButton
                color="error"
                variant="contained"
                disabled={table.getSelectedRowModel().flatRows.length < 1}
                onClick={handleDeactivate}
              >
                Silmək
              </MDButton>
              {
                  enableOrdering && (
                   <MDButton
                    disabled={!enableOrdering || isFetching || isLoading}
                    color="success"
                    variant="contained"
                    onClick={() => {
                       updateDisplayOrder()
                       setEnableOrdering(false);
                    }}
                  >
                    Sıralamaları Yadda Saxla
                  </MDButton>
                  )
                }
                 <Tooltip  arrow title="Refresh Data">
                <IconButton  onClick={() => refetch()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: rowsPerPageOptions,
          showFirstButton: true,
          showLastButton: true,
        }}
        rowCount={data?.totalCount ?? 0}
      
        state={{
          columnFilters,
          globalFilter,
          isLoading: isLoading ,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching || loading,
          sorting,
        }}
      />
      <CreateCouponCodeModal
        open={addCouponCodeModalOpen}
        onClose={() => setAddCouponCodeModalOpen(false)}
        brandId={brandId}
      />
        <MDSnackbar
              color={successSB ? "success" : "error"}
              icon="check"
              title={successSB ? "Uğurlu Əməliyyat" : "Xəta baş verdi"}
              content={successSB ? "Əməliyyat uğurla yerinə yetirildi" : "Əməliyyat zamanı xəta baş verdi"}
              dateTime={new Date().toLocaleString().split(",")[0]}
              open={successSB || errorSB}
              onClose={closeSuccessSB}
              close={closeSuccessSB}
              bgWhite
       />
    </>
  );
};
const CreateCouponCodeModal = ({ open, onClose, brandId }) => {
  const handleSubmit = (values) => {
    values.targetId = brandId;
    agent.Coupon.addCoupon(values)
      .then((response) => {
        console.log(response);
        onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const initialValues = {
    name: "",
    description: "",
    code: "",
    discountPercentage: 0,
    scope: "Brand",
    endDate: new Date(),
  };

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form>
          <Dialog open={open}>
            <DialogTitle textAlign="center">
              <MDTypography variant="h6">Kupon Əlavə et</MDTypography>
            </DialogTitle>
            <DialogContent>
              <Stack
                sx={{
                  width: "100%",
                  minWidth: { xs: "300px", sm: "360px", md: "400px" },
                  gap: "1.5rem",
                }}
              >
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <Field
                      type="text"
                      name="name"
                      id="name"
                      label="Kuponun adı"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                    {}
                    {touched.name && errors.name && errorMessages(errors.name)}
                  </MDBox>
                  <MDBox mb={2}>
                    <Field
                      type="text"
                      name="description"
                      id="description"
                      label="Kupon haqqında"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                    {}
                    {touched.description && errors.description && errorMessages(errors.description)}
                  </MDBox>
                  <MDBox mb={2}>
                    <Field
                      type="text"
                      name="code"
                      id="code"
                      label="Kupon Kodu"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.code}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                    {}
                    {touched.code && errors.code && errorMessages(errors.code)}
                  </MDBox>
                  <MDBox mb={2}>
                    <Field
                      type="number"
                      name="discountPercentage"
                      id="discountPercentage"
                      label="Endirim Faizi"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.discountPercentage}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                    {}
                    {touched.discountPercentage &&
                      errors.discountPercentage &&
                      errorMessages(errors.discountPercentage)}
                  </MDBox>
                  <MDBox mb={2}>
                    <Field
                      type="date"
                      name="endDate"
                      id="endDate"
                      label="Bitiş tarixi"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.endDate}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                    {}
                    {touched.endDate && errors.endDate && errorMessages(errors.endDate)}
                  </MDBox>
                </Grid>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ p: "1.25rem" }}>
              <Button onClick={onClose}>Ləğv Et</Button>
              <Button color="info" onClick={handleSubmit} variant="contained">
                Əlavə Et
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

const Brands = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                   Brendlər
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Brands;
