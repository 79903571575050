import useTableData from "hooks/useTableData";

// Ibeauty Admin Agent 
import agent from "api/agent";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import approvalRequestsTableData from "layouts/users/ApprovalRequests/data/approvalRequestsTableData";

function ApprovalRequests() {
const {totalRows,hasNextPage,columns,loading,fetchData}= useTableData(
  agent.ApprovalRequests.list,
  10,
  approvalRequestsTableData,
  "approval"
)
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                 Kosmetoloq Təsdiq tələbləri
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                
                <DataTable
                 onNextPage ={fetchData}
                 hasNextPage={hasNextPage}
                 totalRows = {totalRows}
                 type="approval"
                 table={{ columns}}
                 loading={loading}
                 isSorted={true}
                 canSearch
                 entriesPerPage={true}
                 showTotalEntries={true}
                 noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default ApprovalRequests;