import React, { useEffect, useMemo, useState } from "react";

import { Formik, Form, Field ,FieldArray} from "formik";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
// Ibeauty Admin Agent
import agent from "api/agent";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from '@mui/material/LinearProgress';

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import SelectBox from "components/MDSelectBox";

// Ant Design components
import { PlusOutlined } from "@ant-design/icons";
import { Divider, Modal, Upload } from "antd";
import { Space, Spin } from 'antd';


// render spinder and desing for center
const spinner = <Spin size="large" style={{ display: "block", margin: "auto" }} />;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const TargetType = [
    { value: 'Product', label: "Məhsul" },
    { value: 'Company', label: "Şirkət" },
    { value: 'Brand', label: "Brend" },
    { value: 'User', label: "Profil"},
    { value: 'WebSite', label: "Web Sayt"},
    { value: 'Instagram', label: "Instagram"},
    { value: 'NoAction', label: "Heç biri"}
  ]

  const SliderPage = [
    { value: 'HomePage', label: "Əsas Səhifə (Home Page Screen)" },
    { value: 'Beautician', label: "Kosmetologlar (Beautician Screen)" },
  ]

function SliderForm() {

  const [sliderPage,setSliderPage] = useState(SliderPage[0].value);
  const [sliderPagelabel,setSliderPagelabel] = useState(TargetType[0].label);

  const [targetType,setTargetType] = useState(TargetType[0].value);
  const [label,setLabel] = useState(TargetType[0].label);


  const [previewOpenAzImage, setPreviewOpenAzImage] = useState(false);
  const [previewImageAzImage, setPreviewImageAzImage] = useState("");
  const [previewTitleAzImage, setPreviewTitleAzImage] = useState("");
  const [fileListAzImage, setFileListAzImage] = useState([]);

  const [previewOpenRuImage, setPreviewOpenRuImage] = useState(false);
  const [previewImageRuImage, setPreviewImageRuImage] = useState("");
  const [previewTitleRuImage, setPreviewTitleRuImage] = useState("");
  const [fileListRuImage, setFileListRuImage] = useState([]);
  
  const [previewOpenEnImage, setPreviewOpenEnImage] = useState(false);
  const [previewImageEnImage, setPreviewImageEnImage] = useState("");
  const [previewTitleEnImage, setPreviewTitleEnImage] = useState("");
  const [fileListEnImage, setFileListEnImage] = useState([]);

  const [previewOpenTrImage, setPreviewOpenTrImage] = useState(false);
  const [previewImageTrImage, setPreviewImageTrImage] = useState("");
  const [previewTitleTrImage, setPreviewTitleTrImage] = useState("");
  const [fileListTrImage, setFileListTrImage] = useState([]);

  const handleCancelAzImage = () => setPreviewOpenAzImage(false);
  const handlePreviewAzImage = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImageAzImage(file.url || file.preview);
    setPreviewOpenAzImage(true);
    setPreviewTitleAzImage(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancelRuImage = () => setPreviewOpenRuImage(false);
  const handlePreviewRuImage = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImageRuImage(file.url || file.preview);
    setPreviewOpenRuImage(true);
    setPreviewTitleRuImage(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancelEnImage = () => setPreviewOpenEnImage(false);
  const handlePreviewEnImage = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImageEnImage(file.url || file.preview);
    setPreviewOpenEnImage(true);
    setPreviewTitleEnImage(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancelTrImage = () => setPreviewOpenTrImage(false);
  const handlePreviewTrImage = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImageTrImage(file.url || file.preview);
    setPreviewOpenTrImage(true);
    setPreviewTitleTrImage(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };


  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );


  const [loading,setLoading] = useState(false);
  const [optionsLoading,setOptionsLoading] = useState(false);
  const [successSB,setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  
  // get all companies from backend and add to SelectBox options 
    const [options,setOptions] = useState([]);

    React.useEffect(() => {
        if(targetType == 'NoAction' || targetType == 'WebSite' || targetType == 'Instagram'){
          setOptions([]);
          return;
        }
        setOptionsLoading(true);
        agent[targetType][targetType == 'User' ? 'cosmetologist' : 'list'](1,1000).then((response) => {
            const options = response?.items.map((option) => {
                return {
                    value: option.id, 
                    label: option.name ?? option.nameSurname,
                    photo: option.logo ?? option.profilePhoto ?? option.pictureUrl
                };
            });
            setOptionsLoading(false);
            setOptions(options);
        });
    }, [targetType]);

  const handleSubmit = (values, actions) => {
    setLoading(true);
     agent.Slider.create(values).then((response) => {
      setLoading(false);
      setSuccessSB(true);
    
    }).catch((error) => {
      setLoading(false);
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Slider Əlavə Et
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={4} px={25}>
                <Formik
                  initialValues={{
                    SliderPage:"",
                    TargetId : "",
                    TargetType : targetType,
                    TargetName : "",
                    TargetPhoto : "",
                    Instagram: "",
                    Website: "",
                    ImageAz : null,
                    ImageEn : null,
                    ImageRu : null,
                    ImageTr : null,
                    cosmetologOnly: false
                  }}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <MDBox component="div" role="form">
                      <MDBox mb={2}>
                      <Field
                          name="SliderPage"
                          label="Slider Hansı Səhifədə Görünəcək"
                          component={SelectBox}
                          options={SliderPage}
                          onChange={
                            (value) => {
                              setFieldValue("SliderPage", value);
                            }
                          }
                         />
                         </MDBox>
                        <MDBox mb={2}>
                        <Field
                          name="TargetType"
                          label="Hədəf Tipi"
                          component={SelectBox}
                          options={TargetType}
                          onChange={
                            (value) => {
                              setFieldValue("TargetId", "");
                              setFieldValue("TargetName", "");
                              setFieldValue("TargetPhoto", "");
                              setFieldValue("Instagram", "");
                              setFieldValue("Website", "");
                              setFieldValue("TargetType", value);
                              setTargetType(value);
                              setLabel(TargetType.find((item) => item.value === value).label);
                            }
                          }
                         />
                        </MDBox>
                        <MDBox mb={2}>
                          {label === "Heç biri" || label === "Web Sayt" || label === "Instagram" ? <></>
                          : 
                           optionsLoading ? spinner :
                          <Field
                          name="TargetId"
                          label={label + " Seçin"}
                          component={SelectBox}
                          options={options? options : []}
                          onChange={(value) => {
                            setFieldValue("TargetId", value)
                            setFieldValue("TargetName", options.find((item) => item.value === value).label)
                            setFieldValue("TargetPhoto", options.find((item) => item.value === value).photo)
                          }
                        }
                          />}
                        </MDBox>
                         {
                            label === "Web Sayt" || label === "Instagram" ?
                            <MDBox mb={2}>
                            <Field
                              type="text"
                              name={label === "Web Sayt" ? "Website" : "Instagram"}
                              label={label === "Web Sayt" ? "Web Sayt" : "Instagram"}
                              id={label === "Web Sayt" ? "Website" : "Instagram"}
                              as={MDInput}
                              variant="standard"
                              fullWidth
                            />
                          </MDBox>
                          : <></>
                         }
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={3}>
                            <MDBox mb={2}>
                            <MDTypography variant="h6" gutterBottom>
                              Azərbaycanca
                            </MDTypography>
                               <Field>
                            {({ field }) => (
                              <>
                                <Upload
                                  customRequest={({ file, onSuccess }) => {
                                    onSuccess("ok");
                                  }}
                                  listType="picture-card"
                                  fileList={fileListAzImage}
                                  accept=".png,.jpg,.jpeg,.gif"
                                  onPreview={handlePreviewAzImage}
                                  onChange={(file) => {
                                    setFileListAzImage(file.fileList);
                                    setFieldValue("ImageAz", file.fileList[0]?.originFileObj);
                                  }}
                                >
                                  {fileListAzImage.length >= 1 ? null : uploadButton}
                                </Upload>
                                <Modal
                                  open={previewOpenAzImage}
                                  title={previewTitleAzImage}
                                  footer={null}
                                  onCancel={handleCancelAzImage}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={previewImageAzImage}
                                  />
                                </Modal>
                              </>
                            )}
                          </Field>
                            
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <MDBox mb={2}>
                            <MDTypography variant="h6" gutterBottom>
                              Ingiliscə
                            </MDTypography>
                            <Field>
                            {({ field }) => (
                              <>
                                <Upload
                                  customRequest={({ file, onSuccess }) => {
                                    onSuccess("ok");
                                  }}
                                  listType="picture-card"
                                  fileList={fileListEnImage}
                                  accept=".png,.jpg,.jpeg,.gif"
                                  onPreview={handlePreviewEnImage}
                                  onChange={(file) => {
                                    setFileListEnImage(file.fileList);
                                    setFieldValue("ImageEn", file.fileList[0]?.originFileObj);
                                  }}
                                >
                                  {fileListEnImage.length >= 1 ? null : uploadButton}
                                </Upload>
                                <Modal
                                  open={previewOpenEnImage}
                                  title={previewTitleEnImage}
                                  footer={null}
                                  onCancel={handleCancelEnImage}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={previewImageEnImage}
                                  />
                                </Modal>
                                </>
                            )}
                          </Field>
                          
                            </MDBox>
                           </Grid>
                          <Grid item xs={12} md={3}>
                            <MDBox mb={2}>
                            <MDTypography variant="h6" gutterBottom>
                             Rusca
                            </MDTypography>
                            <Field>
                            {({ field }) => (
                              <>
                                <Upload
                                  customRequest={({ file, onSuccess }) => {
                                    onSuccess("ok");
                                  }}
                                  listType="picture-card"
                                  fileList={fileListRuImage}
                                  accept=".png,.jpg,.jpeg,.gif"
                                  onPreview={handlePreviewRuImage}
                                  onChange={(file) => {
                                    setFileListRuImage(file.fileList);
                                    setFieldValue("ImageRu", file.fileList[0]?.originFileObj);
                                  }}
                                >
                                  {fileListRuImage.length >= 1 ? null : uploadButton}
                                </Upload>
                                <Modal
                                  open={previewOpenRuImage}
                                  title={previewTitleRuImage}
                                  footer={null}
                                  onCancel={handleCancelRuImage}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={previewImageRuImage}
                                  />
                                </Modal>
                                </>
                            )}
                          </Field>
                           
                            </MDBox>
                           </Grid>
                          <Grid item xs={12} md={3}>
                            <MDBox mb={2}>
                            <MDTypography variant="h6" gutterBottom>
                              Türkcə
                            </MDTypography>
                            <Field>
                            {({ field }) => (
                              <>
                                <Upload
                                  customRequest={({ file, onSuccess }) => {
                                    onSuccess("ok");
                                  }}
                                  listType="picture-card"
                                  fileList={fileListTrImage}
                                  accept=".png,.jpg,.jpeg,.gif"
                                  onPreview={handlePreviewTrImage}
                                  onChange={(file) => {
                                    setFileListTrImage(file.fileList);
                                    setFieldValue("ImageTr", file.fileList[0]?.originFileObj);
                                  }}
                                >
                                  {fileListTrImage.length >= 1 ? null : uploadButton}
                                </Upload>
                                <Modal
                                  open={previewOpenTrImage}
                                  title={previewTitleTrImage}
                                  footer={null}
                                  onCancel={handleCancelTrImage}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={previewImageTrImage}
                                  />
                                </Modal>
                                </>
                            )}
                          </Field>
                           
                            </MDBox>
                            <MDBox mb={2}>
                          <FormControlLabel
                            name="isSpecial"
                            control={<Switch/>}
                            checked={values.cosmetologOnly}
                            label="Kosmetoloqa özəl"
                            onChange={(e) =>
                              setFieldValue("cosmetologOnly", e.target.checked)
                            }
                          />
                        </MDBox>
                           </Grid>
                        </Grid>
                        <MDBox mt={4} mb={1}>
                          <MDButton
                            disabled={loading}
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                            Əlavə Et
                          </MDButton>
                        </MDBox>
                        <MDBox mt={1} mb={1}
                         style={{display:loading ? 'block' : 'none'}}
                        >
                        <LinearProgress color="info" hidden={loading} style={{ width: "50%", margin: "auto" ,position:'relative' }}  />
                          </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </MDBox>
              <MDSnackbar
              color="success"
              icon="check"
              title="Uğurlu Əməliyyat"
              content="Slider uğurla əlavə edildi"
              dateTime={new Date().toLocaleString().split(",")[0]}
              open={successSB}
              onClose={closeSuccessSB}
              close={closeSuccessSB}
              bgWhite
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default SliderForm;
