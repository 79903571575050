import React, { useMemo, useState, useCallback, useEffect } from "react";
import MaterialReactTable from "material-react-table";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Edit, Visibility, Add } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";


import {
  IconButton,
  Tooltip,
  Toolbar,
  Box,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";

// Formik and Yup validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import SelectBox from "components/MDSelectBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";


//Date Picker Imports
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import agent from "api/agent";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";

// const queryClient = new QueryClient();
const Example = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [orderUpdateStatusModal, setOrderUpdateStatusModal] = useState(false);
  const [visibilityModal, setVisibilityModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState({});
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        "api/order/all-orders",
        "https://api.ibu.az/"
      );
      fetchURL.searchParams.set("start", `${pagination.pageIndex + 1}`);
      fetchURL.searchParams.set("size", `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      const response = await agent.Orders.list(fetchURL.search);
      return response;
    },
    keepPreviousData: true,
  });



  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => new Date(row.created), //convert to Date for sorting and filtering
        id: "Created",
        header: "Tarix",
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: "Filter Mode: Less Than",
                  sx: { minWidth: "120px" },
                  variant: "standard",
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
      {
        header: "Sifariş №",
        accessorKey: "orderCode",
        id: "OrderCode",
      },
      {
        header: "Sifariş Statusu",
        accessorKey: "orderStatus",
        id: "OrderStatus",
        size: 200,
        //custom conditional format and styling
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() === "Received"
                  ? theme.palette.info.main
                  : cell.getValue() === "Delivered"
                  ? theme.palette.success.main
                  : cell.getValue() === "Cancelled"
                  ? theme.palette.error.dark
                  : cell.getValue() === "OnTheWay"
                  ? theme.palette.warning.dark
                  : cell.getValue() === "Pending"
                  ? "#FECB00"
                  : "",
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "9ch",
              p: "0.40rem",
            })}
          >
            {cell.getValue() === "Received"
              ? "Sifariş qəbul edildi"
              : cell.getValue() === "Delivered"
              ? "Sifariş çatdırıldı"
              : cell.getValue() === "Cancelled"
              ? "Sifariş ləğv edildi"
              : cell.getValue() === "OnTheWay"
              ? "Sifariş yoldadır"
              : cell.getValue() === "Pending"
              ? "Sifariş hazırlanır"
              : ""}
          </Box>
        ),
      },
      {
        accessorKey: "paymentMethod",
        header: "Ödəniş növü",
        id: "PaymentMethod",
      },
      {
        accessorKey: "peymetStatus",
        header: "Ödəniş statusu",
        id: "PeymetStatus",
        size: 200,
        //custom conditional format and styling
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() === "Pending"
                  ? "#FECB00"
                  : cell.getValue() === "Approve"
                  ? theme.palette.success.main
                  : cell.getValue() === "Cancel"
                  ? "#FF0000"
                  : cell.getValue() === "Decline",
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "9ch",
              p: "0.40rem",
            })}
          >
            {cell.getValue() === "Pending"
              ? "Ödəniş gözləmədə"
              : cell.getValue() === "Approve"
              ? "Ödəniş təsdiqləndi"
              : cell.getValue() === "Decline"
              ? "Uğursuz ödəniş"
              : cell.getValue() === "Cancel"
              ? "İstifadəçi tərəfindən ləğv edildi"
              : ""}
          </Box>
        ),
      },
      {
        accessorKey: "subTotal",
        header: "Cəm",
        id: "SubTotal",
      },
    ],
    []
  );

   const handleOrderUpdateStatus = useCallback((table) => {
     const orders = table.getSelectedRowModel().flatRows.map((row) => row.original.id);
     setOrders(orders);
     setOrderUpdateStatusModal(true);
   }, []);

   const handleDelete = useCallback(async(id) => {
      try {
        if (window.confirm(" Bu sifarişi silmək istədiyinizdən əminsiniz?")){
          await agent.Orders.delete(id);
          refetch();
        } 
      } catch (error) {
        console.log(error);
      }
    }, []);



   const rowsPerPageOptions = [];
     for (let i = 10; i <= data?.totalCount; i += 10) {
       rowsPerPageOptions.push(i);
     }
    rowsPerPageOptions.push(data?.totalCount);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data?.items ?? []}
        initialState={{ showColumnFilters: true }}
        manualFiltering
        manualPagination
        manualSorting
        enableRowActions
        enableRowSelection
        muiSelectCheckboxProps={{
          color: "secondary",
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderRowActions={({ row }) => (
          <Toolbar>
            <IconButton
              onClick={() => {
                setOrder(row.original);
                setVisibilityModal(true);
              }}
            >
              <Visibility
                sx={{
                  color: "green",
                }}
              />
            </IconButton>
            <Button
                color="white"
                sx={{
                  color: "error",
                  backgroundColor: "red",
                }}
                onClick={() => handleDelete(row.original.id)}
                variant="contained"
              >
                Sil
              </Button>
            
          </Toolbar>
          
        )}
        renderTopToolbarCustomActions={({ table }) => {
          const handleDeactivate = () => {
            let selected = table.getSelectedRowModel().flatRows.map((row) => {
              return row.original.id;
            });
            alert(JSON.stringify(selected));
          };

          return (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              <Button
                color="error"
                sx={{
                  color: "green",
                  backgroundColor: "orange",
                }}
                disabled={table.getSelectedRowModel().flatRows.length < 1}
                onClick={() => handleOrderUpdateStatus(table)}
                variant="contained"
              >
                Sifarişin statusunu dəyişmək
              </Button>
            </div>
          );
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: rowsPerPageOptions,
          showFirstButton: true,
          showLastButton: true,
        }}
        rowCount={data?.totalCount ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
      <OrderUpdateStatusModal
        open={orderUpdateStatusModal}
        onClose={() => setOrderUpdateStatusModal(false)}
        orders={orders}
      />

      <VisibilityModal
        open={visibilityModal}
        onClose={() => setVisibilityModal(false)}
        order={order}
      />
    </>
  );
};

const OrderUpdateStatusModal = ({ open, onClose, orders }) => {
  const [status, setStatus] = useState([
    { label: "Sifariş qəbul edildi",value: "Received" },
    { label: "Sifariş çatdırıldı", value: "Delivered" },
    { label: "Sifariş ləğv edildi", value: "Cancelled" },
    { label: "Sifariş yoldadır", value: "OnTheWay" },
    { label: "Sifariş hazırlanır", value: "Pending" },
  ]);

  const handleSubmit = async (values, { setSubmitting }) => {
    values.orderIds = orders;
    try {
      const response = await agent.Orders.updateOrderStatus(values);
      console.log(response);
      setSubmitting(false);
      onClose();
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };

  const initialValues = {
    reasonForCancel:"",
    orderStatus:""
  }


  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form>
          <Dialog open={open}>
            <DialogTitle textAlign="center">
              <MDTypography variant="h6">
                Sifarişin Statusunu Dəyişmək
              </MDTypography>
            </DialogTitle>
            <DialogContent>
              <Stack
                sx={{
                  width: "100%",
                  minWidth: { xs: "300px", sm: "360px", md: "400px" },
                  gap: "1.5rem",
                }}
              >
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <Field
                      name="orderStatus"
                      label="Sifariş Statusu"
                      component={SelectBox}
                      options={status}
                      onChange={(value) => setFieldValue("orderStatus", value)}
                    />
                    {touched.orderStatus && errors.orderStatus && errorMessages(errors.orderStatus)}
                  </MDBox>
                </Grid>
                 {
                values.orderStatus === "Cancelled" && (
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <Field
                      type="text"

                      as={MDInput}
                      name="reasonForCancel"
                      label="Sifarişin ləğv edilmə səbəbi"
                    />
                    {touched.reasonForCancel && errors.reasonForCancel && errorMessages(errors.reasonForCancel)}
                  </MDBox>
                 </Grid>
                   )}
              </Stack>
            </DialogContent>
            <DialogActions sx={{ p: "1.25rem" }}>
              <Button onClick={onClose}>Cancel</Button>
              <Button color="info" onClick={handleSubmit} variant="contained">
                Əlavə Et
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

const VisibilityModal = ({ open, onClose, order }) => {
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">
        <MDTypography variant="h6">Sifarişin Detalları</MDTypography>
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: "100%",
            minWidth: { xs: "300px", sm: "360px", md: "400px" },
            gap: "1.5rem",
          }}
        >
          <Grid item xs={12} md={6}>
            <MDBox mb={2}>
              <MDTypography variant="h6">Müştəri:</MDTypography>
              <MDTypography variant="body2">{order.nameSurname}</MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <MDBox mb={2}>
              <MDTypography variant="h6">Telefon nömrəsi:</MDTypography>
              <MDTypography variant="body2">{order.phoneNumber}</MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <MDBox mb={2}>
              <MDTypography variant="h6">Ünvan:</MDTypography>
              <MDTypography variant="body2">{order.shippingAddress?.district}</MDTypography>
              {order.shippingAddress?.longitude && order.shippingAddress?.latitude && (
                <Button
                  variant="contained"
                  color="info"
                  sx={{
                    color: "white",
                    backgroundColor: "orange",
                  }}
                >
                  <a href={`https://www.google.com/maps/search/?api=1&query=${order.shippingAddress?.latitude},${order.shippingAddress?.longitude}`} target="_blank" rel="noreferrer">
                    Google Maps
                  </a>
                </Button>
              )}
            </MDBox>
          </Grid>
          {order?.orderItems?.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <MDTypography variant="h6">Məhsulun şəkli:</MDTypography>
              <MDAvatar
                src={item.pictureUrl}
                alt={item.name}
                sx={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "0.5rem",
                }}
              />
              <MDBox mb={2}>
                <MDTypography variant="h6">Məhsul:</MDTypography>
                <MDTypography variant="body2">{item.name}</MDTypography>
                <MDTypography variant="h6">Şirkət:</MDTypography>
                <MDTypography variant="body2">{item?.companyName}</MDTypography>
                <MDTypography variant="h6">Qiymət:</MDTypography>
                <MDTypography variant="body2">{item.price}</MDTypography>
                <MDTypography variant="h6">Say:</MDTypography>
                <MDTypography variant="body2">{item.quantity}</MDTypography>
              </MDBox>
            </Grid>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

const orderstatuses = [
  { label: "Sifariş qəbul edildi", value: "Received"},
  { label: "Sifariş çatdırıldı", value: "Delivered"},
  { label: "Sifariş ləğv edildi", value: "Cancelled"},
  { label: "Sifariş yoldadır", value: "OnTheWay"},
  { label: "Sifariş hazırlanır", value: "Pending"},
];

const Orders = () => (

  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Sifarişlər
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Grid item xs={1} md={2}>
                  <MDBox mb={2}>
                    <SelectBox
                      name="status"
                      label="Sifariş Statusu"
                      defaultValue={orderstatuses[0]}
                      options={orderstatuses ? orderstatuses : []}
                      onChange={(value) => changeStatus(value)}
                    />
                  </MDBox>
                </Grid>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Orders;
