/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import * as React from 'react';
import { Link } from 'react-router-dom';

// @mui icons
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDialolgBox from "components/MDDialogBox";
import UserDocumentViewer from 'layouts/users/ApprovalRequests/components/UserDocumentViewer';
import { formatDateAz } from 'utils/dateUtils';

export default function data(data) {
  // Modal 
  const Author = ({name, email ,phoneNumber}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email ?? phoneNumber}</MDTypography>
      </MDBox>
    </MDBox>
  );


  const Document = (doc) => {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
      setOpen(false);
    };
    return (
      <MDBox lineHeight={1} textAlign="left">
        <Link 
         to="#"
         onClick={() => setOpen(true)}
        >
         <DocumentScannerIcon  color="disabled" fontSize="large" />
        </Link>
        <MDDialolgBox open={open} title="Sənədlər" handleClose={handleClose} >
          <UserDocumentViewer doc={doc} setOpen={setOpen} />
        </MDDialolgBox>
      </MDBox>
    );
  };

  const rows = data?.map((item) => ({
    userId: item.userId,
    author: <Author name={item.nameSurname} email={item.email} phoneNumber={item.phoneNumber} />,
    documents: <Document 
      key={item.userId}
      certificate={item.certificate}
      identityCard={item.identityCard}
      userId={item.userId}
    />,
    date: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {item.created && formatDateAz(item.created)}
      </MDTypography>
    ),
  }));

  return {

    columns: [
      { Header: "Kosmetoloq", accessor: "author", align: "left" },
      { Header: "Tarix", accessor: "date", align: "center" },
      { Header: "Sənədlər", accessor: "documents", align: "left",align: "end" },
    ],
    rows: rows,

  };
}
