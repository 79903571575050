// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { useLocation } from "react-router-dom"

import { useEffect, useState } from "react";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Overview page components
import ProductDetailPhotos from "layouts/product/components/ProductDetailPhotos";

import ProductInfoCard from "layouts/product/components/ProductInfoCard";

import agent from "api/agent";
import { Card,CardContent, Typography } from '@mui/material';
import { isString } from "formik";

function Overview() {
  const location = useLocation();
  const id = location.state.id
  const [data, setData] = useState({});
  const [createdby, setCreatedby] = useState("Tabriz Habiyev");
  const [lastModifiedby, setLastModifiedby] = useState("Günel Yusubova");
  const { 
    name, 
    price, 
    images, cosmetologOnly, 
    commissionRate, stock,
    productOnHand, brand,
    company, created, 
   lastModified,
    descriptions } = data;

  useEffect(() => {
    if(isString(id)){
      agent.Product.details(id)
      .then((response) => {
        setData(response);
      })
      .catch((error) => console.log(error));
    }
  }, []);
 
  return (
    <DashboardLayout>
    <DashboardNavbar />
    <MDBox mb={2} />
      <MDBox 
      mt={5} 
      mb={3}
      variant="gradient"
    
      borderRadius="lg"
      coloredShadow="info"
      >
        <Grid container spacing={2}>
<Grid item xs={12} md={6}>
{images?.length > 0 && (
<ProductDetailPhotos images={images} />
)}
</Grid>
<Grid item xs={12} md={6}>
<Card sx={{ maxWidth: 600 }}>
<CardContent>
<Typography variant="h5" component="div">
{name}
</Typography>
<Divider />
<ProductInfoCard
 info={{
   'Qiyməti': price + ' AZN',
   'Stok Miqdarı': stock,
   'Stokda Qalıb': productOnHand,
   'Kosmetoloqa Kommisiyası': commissionRate + '%',
   'Kosmetoloq üçün': cosmetologOnly ? 'Bəli' : 'Xeyr',
 }}/>
<Divider sx={{ mt: 2, mb: 2 }} />
<ProductInfoCard
title="Ətraflı məlumat"
 description={descriptions}
 info={{
   'Brend': brand,
   'Şirkət': company,
   'Əlavə edildi': new Date(created).toLocaleString().toString(),
   'Əlavə edən': createdby,
   'Son dəyişiklik': new Date(lastModified).toLocaleString().toString(),
   'Son dəyişiklik edən': lastModifiedby,
 }}
/>
</CardContent>
</Card>
</Grid>
</Grid>
      </MDBox>
  </DashboardLayout>
  );
}

export default Overview;
