import React, { useState } from "react";
import { useLocation } from "react-router-dom"

import { Formik, Form, Field } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Switch } from "@mui/material";
// Ibeauty Admin Agent
import agent from "api/agent";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from '@mui/material/LinearProgress';

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";



// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import SelectBox from "components/MDSelectBox";


// Ant Design components
import { PlusOutlined } from "@ant-design/icons";
import { Divider, Modal, Upload } from "antd";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function BrandForm({type}) {
  const location = useLocation();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState(
    type === 'create' ? [] : [
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: location.state.logo,
      }
    ]
  );

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  
  const [loading,setLoading] = useState(false);
  const [successSB,setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  
  // get all companies from backend and add to SelectBox options 
    const [companies,setCompanies] = useState([]);

    React.useEffect(() => {
        agent.Company.list(1,1000).then((response) => {
            const companies = response?.items.map((company) => {
                return {
                    value: company.id,
                    label: company.name,
                };
            });
            setCompanies(companies);
        });
    }, []);


    
  const handleSubmit = (values, actions) => {
    setLoading(true);
    agent.Brand[type](values).then(() => {
    actions.setSubmitting(false);
    setSuccessSB(true);
    setFileList([]);
    setLoading(false);
  }).catch((err) => {
    setLoading(false);
  });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                    {type === 'create' ? "Brend Əlavə Et" : "Brendi Yenilə"}
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={4} px={25}>
                <Formik
                  initialValues={{
                    id: type === 'create' ? null : location.state.id,
                    name: type === 'create' ? '' : location.state.name,
                    companyId : type === 'create' ? null : location.state.companyId,
                    brandPhoto: null,
                    cosmetologOnly: type === 'create' ? true : location.state.cosmetologOnly,
                  }}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <MDBox component="div" role="form">
                        <MDBox mb={2}>
                          <Field
                            type="text"
                            name="name"
                            label="Brend Adı"
                            as={MDInput}
                            variant="standard"
                            fullWidth
                          />
                        </MDBox>
                        <MDBox mb={2}>
                           <Field
                              name="companyId"
                              label="Şirkət Seçin"
                              component={SelectBox}
                              options={companies? companies : []}
                              onChange={(value) => setFieldValue("companyId", value)}
                            />
                        </MDBox>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                               <Field>
                            {({ field }) => (
                              <>
                                <Upload
                                  customRequest={({ file, onSuccess }) => {
                                    onSuccess("ok");
                                  }}
                                  listType="picture-card"
                                  fileList={fileList}
                                  accept=".png,.jpg,.jpeg,.gif"
                                  onPreview={handlePreview}
                                  onChange={(file) => {
                                    setFileList(file.fileList);
                                    setFieldValue("brandPhoto", file.fileList[0].originFileObj);
                                  }}
                                >
                                  {fileList.length >= 1 ? null : uploadButton}
                                </Upload>
                                <Modal
                                  open={previewOpen}
                                  title={previewTitle}
                                  footer={null}
                                  onCancel={handleCancel}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={previewImage}
                                  />
                                </Modal>
                              </>
                            )}
                          </Field>
                            </MDBox>
                    <MDBox mb={2}>
                          <FormControlLabel
                            name="cosmetologOnly"
                            control={<Switch/>}
                            checked={values.cosmetologOnly}
                            label="Kosmetoloqa özəl"
                            onChange={(e) =>
                              setFieldValue("cosmetologOnly", e.target.checked)
                            }
                          />
                        </MDBox>
                          </Grid>
                        </Grid>
                        <MDBox display="flex" alignItems="center" ml={-1}>
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                          <MDButton
                            disabled={loading}
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                           {type === 'create' ? 'Əlavə Et' : 'Yenilə'}
                          </MDButton>
                        </MDBox>
                        <MDBox mt={1} mb={1}
                         style={{display:loading ? 'block' : 'none'}}
                        >
                        <LinearProgress color="info" hidden={true} style={{ width: "50%", margin: "auto" ,position:'relative' }}  />
                          </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </MDBox>
              <MDSnackbar
              color="success"
              icon="check"
              title="Uğurlu Əməliyyat"
              content={"Brend uğurla " + (type === 'create' ? 'əlavə edildi' : 'yeniləndi')}
              dateTime={new Date().toLocaleString().split(",")[0]}
              open={successSB}
              onClose={closeSuccessSB}
              close={closeSuccessSB}
              bgWhite
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default BrandForm;
