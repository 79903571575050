import React, { useState } from "react";
import { useLocation } from "react-router-dom"

import { Formik, Form, Field } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Switch } from "@mui/material";
// Ibeauty Admin Agent
import agent from "api/agent";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from '@mui/material/LinearProgress';

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import ImageInput from "components/MDFileInput";
import MDSnackbar from "components/MDSnackbar";

function CompanyForm({type}) {
  const location = useLocation();

  const [loading,setLoading] = useState(false);
  const [successSB,setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const handleSubmit = (values, actions) => {
    setLoading(true);
      agent.Company[type](values).then(() => {
      actions.setSubmitting(false);
      setSuccessSB(true);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                    {type === 'create' ? 'Şirkət Əlavə Et' : 'Şirkəti Yenilə'}
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={4} px={25}>
                <Formik
                  initialValues={{
                    id: type === 'create' ? null : location.state.id,
                    name: type === 'create' ? '' : location.state.name,
                    email : type === 'create' ? '' : location.state.email,
                    phoneNumber : type === 'create' ? '' : location.state.phoneNumber, 
                    webSite : type === 'create' ? '' : location.state.webSite,
                    cosmetologOnly: type === 'create' ? true : location.state.cosmetologOnly,
                    logo: null,
                    cover: null,
                  }}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <MDBox component="div" role="form">
                        <MDBox mb={2}>
                          <Field
                            type="text"
                            name="name"
                            label="Name"
                            as={MDInput}
                            variant="standard"
                            fullWidth
                          />
                        </MDBox>
                        <MDBox mb={2}>
                          <Field
                            type="text"
                            name="email"
                            label="Email"
                            as={MDInput}
                            variant="standard"
                            fullWidth
                          />
                        </MDBox>
                        <MDBox mb={2}>
                          <Field
                            type="text"
                            name="phoneNumber"
                            label="Phone Number"
                            as={MDInput}
                            variant="standard"
                            fullWidth
                          />
                        </MDBox>
                        <MDBox mb={2}>
                          <Field
                            type="text"
                            name="webSite"
                            label="Web Site"
                            as={MDInput}
                            variant="standard"
                            fullWidth
                          />
                        </MDBox>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <ImageInput
                                label="Choose Logo"
                                name="logo"
                                preview={type === 'create' ? null : location.state.logo}
                                onChange={(logo) =>
                                  setFieldValue("logo", logo)
                                }
                                onDelete={() => setFieldValue("logo", null)}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <ImageInput
                                label="Choose Cover"
                                name="cover"
                                preview={type === 'create' ? null : location.state.cover}
                                onChange={(cover) =>
                                  setFieldValue("cover", cover)
                                }
                                onDelete={() => setFieldValue("cover", null)}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <MDBox display="flex" alignItems="center" ml={-1}>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControlLabel
                            name="cosmetologOnly"
                            control={<Switch/>}
                            checked={values.cosmetologOnly}
                            label="Kosmetoloqa özəl"
                            onChange={(e) =>
                              setFieldValue("cosmetologOnly", e.target.checked)
                            }
                          />
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                          <MDButton
                            disabled={loading}
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                           {type === 'create' ? 'Əlavə Et' : 'Yenilə'}
                          </MDButton>
                        </MDBox>
                        <MDBox mt={1} mb={1}
                         style={{display:loading ? 'block' : 'none'}}
                        >
                        <LinearProgress color="info" hidden={true} style={{ width: "50%", margin: "auto" ,position:'relative' }}  />
                          </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </MDBox>
              <MDSnackbar
              color="success"
              icon="check"
              title="Uğurlu Əməliyyat"
              content={type === 'create' ? 'Şirkət Əlavə Edildi' : 'Şirkət Yeniləndi'}
              dateTime={new Date().toLocaleString().split(",")[0]}
              open={successSB}
              onClose={closeSuccessSB}
              close={closeSuccessSB}
              bgWhite
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CompanyForm;
