import { useState, useEffect,useCallback } from "react";
// react-redux hooks
import {useDispatch } from 'react-redux';
import { setRows}  from "redux/tableDataSlice";

function useTableData(agent,pageSize,processData,type){
  const dispatch = useDispatch();
  const [totalRows, setTotalRows] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = useCallback(async (pageIndex,pageSize) => {
    setLoading(true);
      try {
        const response = await agent(pageIndex,pageSize);
        setHasNextPage(response.hasNextPage);
        setTotalRows(response.totalCount);
        setData([...data, ...response.items]);
        setLoading(false);
      }
      catch (error) {
        setLoading(false);
      }
  }, [agent,pageSize,data]);
    const {columns,rows} = processData(data)
    dispatch(setRows({type:type,data:rows}));
    useEffect(() => {
        fetchData(1,pageSize);
    }, []);
  return {totalRows,hasNextPage,columns,loading,fetchData}
}
export default useTableData;