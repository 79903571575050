// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ProductInfoCard({title, description, info, shadow }) {
    const labels = [];
    const values = [];
    const icons = [];
  
    // Convert object keys to readable labels and extract values and icons
    Object.keys(info).forEach((key) => {
      const label = key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
      const value = typeof info[key] === 'object' ? info[key].value : info[key];
      const icon = typeof info[key] === 'object' ? info[key].icon : null;
      labels.push(label);
      values.push(value);
      icons.push(icon);
    });
  
    // Render the card info items
    const renderInfo = labels.map((label, index) => (
      <MDBox key={label} display="flex" py={1} pr={2}>
        {icons[index] && icons[index]}
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {label}: &nbsp;
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{values[index]}
        </MDTypography>
      </MDBox>
    ));
    

    const FlagIcon = ({ code, size }) => (
        <img src={`https://flagcdn.com/${size}/${code}.png`} alt={code} />
    );

  const renderFlag = (code) => {
    switch (code) {
        case "az-AZ":
            return <FlagIcon code="az" size="28x21" />;
        case "en-US":
            return <FlagIcon code="gb" size="28x21" />;
        case "ru-RU":
            return <FlagIcon code="ru" size="28x21" />;
        case "tr-TR":
            return <FlagIcon code="tr" size="28x21" />;
        default:
            return <FlagIcon code="az" size="28x21" />;
    }
    };

    // Create description 
    const renderDescription = description?.map((el) => (
        <MDBox 
        key={el.languageCode}
        m={1}
        display="flex" 
        py={1} 
        pr={2}
        variant="gradient"
        borderRadius="lg"
        coloredShadow="info"
        >
        <MDTypography p={1} variant="button" fontWeight="bold" textTransform="capitalize">
                {renderFlag(el.languageCode)}&nbsp;
            </MDTypography>
            <MDTypography p={3} variant="button" fontWeight="regular" color="text">
                {el.description}
            </MDTypography>
        </MDBox>
    ));

    return (
        <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={1}>
          <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
            {title}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDBox opacity={0.3}>
            <Divider />
          </MDBox>
          <MDBox>
            {renderInfo}
          </MDBox>
        </MDBox>
        <MDBox mb={2} lineHeight={1}>
             <MDTypography variant="button" color="text" fontWeight="light">
              {renderDescription}
            </MDTypography>
          </MDBox>
      </Card>
    );
}

export default ProductInfoCard;