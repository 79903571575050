import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    notification: {},
    loadingAction: false,
};

export const globalNotificationSlice = createSlice({
    name: "globalNotification",
    initialState,
    reducers: {
        setNotification: (state, action) => {
            state.notification = action.payload;
        },
        removeNotification: (state, action) => {
            state.notification = {};
        },
        loadingAction: (state, action) => {
            state.loadingAction = action.payload;
        },
    },
});

export const { setNotification, removeNotification, loadingAction } = globalNotificationSlice.actions;
export default globalNotificationSlice.reducer;

