import React, { useMemo, useState, useCallback,useEffect } from "react";
import MaterialReactTable from "material-react-table";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Edit, AccountCircle, Percent,   Done, Visibility, Delete } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";

// Agent import
import agent from "api/agent";

import {
  IconButton,
  Tooltip,
  Box,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  ListItemIcon, 
  Typography,
  TextField,
} from "@mui/material";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

// Formik and Yup validation
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import SelectBox from "components/MDSelectBox";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { FOCUSABLE_SELECTOR } from "@testing-library/user-event/dist/utils";

// const queryClient = new QueryClient();
const Example = () => {
  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
    ],
    queryFn: async () => {
      const response = await agent.Couriers.list();
      return response;
    },
    keepPreviousData: true,
  });

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, 
        id: "Name", 
        header: "Ad Soyad",
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
           {
            row.original.profilePhotoUrl ? (
              <img
                src={row.original.profilePhotoUrl}
                alt="profile"
                style={{
                  width: "2.5rem",
                  height: "2.5rem",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <AccountCircle
                sx={{
                  width: "2.5rem",
                  height: "2.5rem",
                  borderRadius: "50%",
                  fontSize: "2.5rem",
                  fontWeight: 500,
                  
                }}
              />
            )
           }
            <Typography variant='inherit'>{renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "isBusy",
        id: "isBusy",
        header: "Status",
        size: 100,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              color: '#fff',          
              backgroundColor: cell.getValue() ?  theme.palette.error.main : theme.palette.success.main,
              borderRadius: "5px",
              padding: "0.25rem 0.5rem",
              fontSize: "0.75rem",
              fontWeight: 500,
              lineHeight: 1.75,
              letterSpacing: "0.02857em",
              textTransform: "uppercase"
            })}
          >
            {cell.getValue() ? "Məşğul" : "Boş"}
          </Box>
        ),
      },
      {
        accessorKey: "accepted",
        id: "Accepted",
        header: "Təsdiq statusu",
        size: 100,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              color: '#fff',          
              backgroundColor: cell.getValue() ? theme.palette.success.main : theme.palette.error.main,
              borderRadius: "5px",
              padding: "0.25rem 0.5rem",
              fontSize: "0.75rem",
              fontWeight: 500,
              lineHeight: 1.75,
              letterSpacing: "0.02857em",
              textTransform: "uppercase"
            })}
          >
            {cell.getValue() ? "Təsdiqlənib" : "Təsdiqlənməyib"}
          </Box>
        ),

      },
    ],
    []
  );


  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        initialState={{ showColumnFilters: FOCUSABLE_SELECTOR }}
        manualFiltering={false}
        manualPagination={true}
        manualSorting={false}
        enableRowActions={true}
        enableRowSelection={false}
        muiSelectCheckboxProps={{
          color: "secondary",
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        renderRowActionMenuItems={({ closeMenu,row }) => [
         !row.original.accepted &&(
            <MenuItem
              key={1}
              onClick={() => {
                agent.Couriers.acceptOrReject({
                    courierId: row.original.id,
                    isAccepted: true
                }).then(() => refetch());
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Done  color="success"/>
              </ListItemIcon>
                Təsdiq et
            </MenuItem>
          ),
          <MenuItem
            key={1}
            onClick={() => {
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
             <Visibility color="info"/>
            </ListItemIcon>
              Profilə bax
          </MenuItem>,
            <MenuItem
            key={2}
            onClick={() => {
              agent.Couriers.delete(row.original.id).then(() => refetch());
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Delete color="error" />
            </ListItemIcon>
            Sil
          </MenuItem>,
        ]}
        rowCount={data?.length ?? 0}
        state={{
          isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
        }}
      />
    </>
  );
};



const Couriers = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Kuryerlər
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Couriers;
