import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, FieldArray } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { Alert, Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Upload, Modal, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import agent from "api/agent";
import {useNavigate } from "react-router-dom";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const { Option } = Select;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const flags = {
  "az-AZ": "https://flagcdn.com/az.svg",
  "tr-TR": "https://flagcdn.com/tr.svg",
  "en-US": "https://flagcdn.com/us.svg",
  "ru-RU": "https://flagcdn.com/ru.svg",
};

function CategoryForm({ type }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState(
    type === "create"
      ? []
      : [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: location.state?.photo || "",
          },
        ]
  );

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const [loading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await agent.Catalog.Brands();
        setBrands(response);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, []);

  const handleBrandChange = async (value) => {
    setSelectedBrand(value);
    try {
      const response = await agent.Catalog.ProductsByBrand(value);
      setProducts(response);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const payload = {
        cosmetologOnly: values.cosmetologOnly,
        categoryLanguages: values.categoryLanguages,
        photo: fileList[0]?.originFileObj || null,
      };

      if(type === 'add-products')
      {
        for (const prop of Object.getOwnPropertyNames(payload)) {
          delete payload[prop];
        }
        payload.categoryId = values.id
        payload.productIds = values.productIds
      }
      else if(type === 'edit'){
        payload.id = values.id
      }else{
        payload.productIds = values.productIds
      }
      
      await agent.Category[
      type === 'create' ? 'Add' : 
      type === 'edit' ? 'Update' :
      'AddProducts'
      ](payload);
      setSuccessSB(true);
      actions.resetForm();
      setFileList([]);
      if(type === 'edit' || type === 'add-products')
        navigate("/category/categories")
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      actions.setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {type === "create" ?  "Kateqoriya Əlavə Et" : type === 'edit' ?  "Kateqoriyaya düzəliş edin" : 'Kateqoriyayaya məhsul əlavə et'}
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={4} px={25}>
                <Formik
                  initialValues={{
                    id: type === "create" ? null : location.state?.id,
                    cosmetologOnly: type === "create" ? true : location.state?.cosmetologOnly,
                    categoryLanguages: type === "create"
                      ? [{ languageCode: "az-AZ", name: "", isDefault: true }]
                      : location.state?.categoryLanguages || [],
                    productIds: [] ,
                    photo: type === "create" ? [] : [location.state?.photo || ''],
                  }}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                       {type !== 'add-products' && 
                         <FieldArray name="categoryLanguages">
                         {({ push, remove }) => (
                           <>
                             {values.categoryLanguages?.map((language, index) => (
                               <MDBox mb={2} key={index}>
                                 <Grid container spacing={2}>
                                   <Grid item xs={1}>
                                     <img
                                       src={flags[language.languageCode] || "default-flag.png"}
                                       alt={language.languageCode}
                                       style={{ width: "100%" }}
                                     />
                                   </Grid>
                                   <Grid item xs={9}>
                                     <Field
                                       name={`categoryLanguages.${index}.name`}
                                       label={`Kateqoriya Adı (${language.languageCode})`}
                                       as={MDInput}
                                       variant="standard"
                                       fullWidth
                                     />
                                   </Grid>
                                   <Grid item xs={2}>
                                     {language.languageCode !== 'az-AZ' && (
                                       <MDButton
                                         variant="text"
                                         color="error"
                                         onClick={() => remove(index)}
                                       >
                                         Sil
                                       </MDButton>
                                     )}
                                   </Grid>
                                 </Grid>
                               </MDBox>
                             ))}
                             <MDButton
                               variant="text"
                               color="info"
                               onClick={() => {
                                 const availableLanguages = ["tr-TR", "en-US", "ru-RU"];
                                 const usedLanguages = values.categoryLanguages?.map(
                                   (lang) => lang.languageCode
                                 );
                                 const unusedLanguages = availableLanguages.filter(
                                   (lang) => !usedLanguages.includes(lang)
                                 );
                                 if (unusedLanguages.length > 0) {
                                   push({
                                     languageCode: unusedLanguages[0],
                                     name: "",
                                     isDefault: false,
                                   });
                                 }
                               }}
                             >
                               Dil Əlavə Et
                             </MDButton>
                           </>
                         )}
                       </FieldArray>
                       }
                      <Grid container spacing={2}>
                      {type !== 'add-products' &&
                         <Grid item xs={12} md={6}>
                         <MDBox mb={2}>
                           <Upload
                             customRequest={({ file, onSuccess }) => {
                               onSuccess("ok");
                             }}
                             listType="picture-card"
                             fileList={fileList}
                             accept=".png,.jpg,.jpeg,.gif,.svg"
                             onPreview={handlePreview}
                             onChange={(info) => {
                               setFileList(info.fileList);
                               setFieldValue("photo", info.fileList[0]?.originFileObj || null);
                             }}
                           >
                             {fileList.length >= 1 ? null : uploadButton}
                           </Upload>
                           <Modal
                             open={previewOpen}
                             title={previewTitle}
                             footer={null}
                             onCancel={handleCancel}
                           >
                             <img
                               alt="example"
                               style={{ width: "100%" }}
                               src={previewImage}
                             />
                           </Modal>
                         </MDBox>
                         <MDBox mb={2}>
                           <FormControlLabel
                             name="cosmetologOnly"
                             control={<Switch />}
                             checked={values.cosmetologOnly}
                             label="Kosmetoloqa özəl"
                             onChange={(e) =>
                               setFieldValue("cosmetologOnly", e.target.checked)
                             }
                           />
                         </MDBox>
                       </Grid>
                      }
                        {type === "create" || type ==='add-products' ?
                          <Grid item xs={12} md={6}>
                          <MDBox mb={2}>
                            <Select
                              showSearch
                              placeholder="Brand Seçin"
                              optionFilterProp="children"
                              onChange={handleBrandChange}
                              style={{ width: "100%" }}
                              value={selectedBrand}
                            >
                              {brands?.map((brand) => (
                                <Option key={brand.id} value={brand.id}>
                                  {brand.name}
                                </Option>
                              ))}
                            </Select>
                          </MDBox>
                          <MDBox mb={2}>
                            <Select
                              mode="multiple"
                              placeholder="Məhsul Seçin"
                              style={{ width: "100%" }}
                              value={values.productIds}
                              onChange={(value) => setFieldValue("productIds", value)}
                            >
                              {products?.map((product) => (
                                <Option key={product.id} value={product.id}>
                                  {product.name}
                                </Option>
                              ))}
                            </Select>
                          </MDBox>
                        </Grid>
                       : <></> }
                      </Grid>
                      <MDBox mt={4} mb={1}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? <LinearProgress color="inherit" /> : "Təsdiqlə"}
                        </MDButton>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDSnackbar
        color="success"
        icon="check"
        title={type === 'create' ? 'Kateqoriya əlavə edildi' : 'Kateqoriya Yeniləndi'}
        content="Kateqoriya uğurla Yeniləndi"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
      />
    </DashboardLayout>
  );
}

export default CategoryForm;
