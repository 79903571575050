import React, { useMemo, useState, useCallback } from "react";
import MaterialReactTable from "material-react-table";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Edit, Visibility, Add } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";

// Agent import
import agent from "api/agent";

import {
  IconButton,
  Tooltip,
  Toolbar,
  Box,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import {AccountCircle } from "@mui/icons-material";

// Formik and Yup validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import SelectBox from "components/MDSelectBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const Example = () => {
  const [columnFilters, setColumnFilters] = useState([
    {"id":"UserType","value":"2"}
  ]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [addCouponCodeModalOpen, setAddCouponCodeModalOpen] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        "api/Users/get-users-with-query-model",
        "https://api.ibu.az/"
      );
      fetchURL.searchParams.set("start", `${pagination.pageIndex + 1}`);
      fetchURL.searchParams.set("size", `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      const response = await agent.User.GetUsersWithQueryModel(fetchURL.search);
      return response;
    },
    keepPreviousData: true,
  });

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.nameSurname}`, //accessorFn used to join multiple data into a single cell
        id: "NameSurname", //id is still required when using accessorFn instead of accessorKey
        header: "Ad Soyad",
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
           {
            row.original.profilePhoto ? (
              <img
                src={row.original.profilePhoto}
                alt="profile"
                style={{
                  width: "2.5rem",
                  height: "2.5rem",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <AccountCircle
                sx={{
                  width: "2.5rem",
                  height: "2.5rem",
                  borderRadius: "50%",
                  fontSize: "2.5rem",
                  fontWeight: 500,
                  
                }}
              />
            )
           }
            <Typography variant='inherit'>{renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "email",
        header: "Email",
        id: "Email",
      },
      {
        accessorKey: "phoneNumber",
        header: "Telefon",
        id: "PhoneNumber",
      },
      {
        accessorKey: "gender",
        header: "Cins",
        id: "Gender",
      },
      {
        accessorKey: "birthday",
        header: "Doğum tarixi",
        id: "Birthday",
      },
    ],
    []
  );


   const [userIds, setUserIds] = useState([]);

   const handleAddUserToCoupon = useCallback((table) => {
     const userIds = table.getSelectedRowModel().flatRows.map((row) => row.original.id);
     setUserIds(userIds);
     setAddCouponCodeModalOpen(true);
   }, []);

   const rowsPerPageOptions = [];
     for (let i = 10; i <= data?.totalCount; i += 10) {
       rowsPerPageOptions.push(i);
     }
    rowsPerPageOptions.push(data?.totalCount);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data?.items ?? []}
        initialState={{ showColumnFilters: true }}
        manualFiltering
        manualPagination
        manualSorting
        enableRowActions
        enableRowSelection
        muiSelectCheckboxProps={{
          color: "secondary",
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderRowActions={({ row }) => (
          <Toolbar>
            <IconButton onClick={() => alert(row.original.id)}>
              <Visibility
                sx={{
                  color: "green",
                }}
              />
            </IconButton>
            <IconButton onClick={() => alert(row.original.id)}>
              <Edit
                sx={{
                  color: "orange",
                }}
              />
            </IconButton>
          </Toolbar>
        )}
        renderTopToolbarCustomActions={({ table }) => {
          const handleDeactivate = () => {
            let selected = table.getSelectedRowModel().flatRows.map((row) => {
              return row.original.id;
            });
            alert(JSON.stringify(selected));
          };

          return (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <Button
                color="error"
                sx={{
                  color: "white",
                  backgroundColor: "red",
                }}
                disabled={table.getSelectedRowModel().flatRows.length < 1}
                onClick={handleDeactivate}
                variant="contained"
              >
                Silmək
              </Button>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>

              <Button
                color="info"
                onClick={() => {
                  handleAddUserToCoupon(table);
                }}
                variant="contained"
                disabled={table.getSelectedRowModel().flatRows.length < 1}
              >
                Kupon Əlavə et
              </Button>
            </div>
          );
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: rowsPerPageOptions,
          showFirstButton: true,
          showLastButton: true,
        }}
        rowCount={data?.totalCount ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
      <CreateCouponCodeModal
        open={addCouponCodeModalOpen}
        onClose={() => setAddCouponCodeModalOpen(false)}
        userIds={userIds}
      />
    </>
  );
};


const CreateCouponCodeModal = ({ open, onClose, userIds }) => {
  const [coupons, setCoupons] = useState([]);
  React.useEffect(() => {
    agent.Coupon.list().then((response) => {
      const coupon = response?.map((coupon) => {
        return {
          value: coupon.id,
          label: coupon.name,
        };
      });
      setCoupons(coupon);
    });
  }, []);

  const handleSubmit = (values) => {
    values.userIds = userIds;
    agent.Coupon.assignCouponToUser(values).then((response) => {
      if (response) {
         console.log(response);
         onClose();
      }
    });
  };
  const initialValues = {
    couponId: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form>
          <Dialog open={open}>
            <DialogTitle textAlign="center">
              <MDTypography variant="h6">Kupon Əlavə et</MDTypography>
            </DialogTitle>
            <DialogContent>
              <Stack
                sx={{
                  width: "100%",
                  minWidth: { xs: "300px", sm: "360px", md: "400px" },
                  gap: "1.5rem",
                }}
              >
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <Field
                      name="couponId"
                      label="Kuponlar"
                      component={SelectBox}
                      options={coupons ? coupons : []}
                      onChange={(value) => setFieldValue("couponId", value)}
                    />
                    {touched.couponId && errors.couponId && errorMessages(errors.couponId)}
                  </MDBox>
                </Grid>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ p: "1.25rem" }}>
              <Button onClick={onClose}>Cancel</Button>
              <Button color="info" onClick={handleSubmit} variant="contained">
                Əlavə Et
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};


const Customers = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Müştərilər
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Customers;
