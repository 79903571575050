import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// Formik and Yup validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

//Date Picker Imports
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
// Ibeauty Admin Agent
import agent from "api/agent";

// Ant Design components
import { PlusOutlined } from "@ant-design/icons";
import { Divider, Modal, Typography, Upload } from "antd";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import SelectBox from "components/MDSelectBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function DiscountForm({ type }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const initialValues = {
    name: "",
    productIds: [],
    startDate: new Date(),
    endDate: new Date(),
    percentage: 0,
  };

  const errorMessages = (error) => {
    return (
      <MDBox component="span" color="error.main">
        {error}
      </MDBox>
    );
  };
  const validationSchema = Yup.object().shape({
    brandId: Yup.string().required("Zəhmət olmasa şirkət seçin"),
    name: Yup.string()
      .required("Zəhmət olmasa adı daxil edin")
      .min(4, "Ad çox qısadır!")
      .max(50, "Ad çox uzundur!"),
    price: Yup.number()
      .min(1, "Qiymət 0-dan böyük olmalıdır")
      .required("Zəhmət olmasa qiymət daxil edin"),
    cost: Yup.number()
      .min(1, "Qiymət 0-dan böyük olmalıdır")
      .required("Zəhmət olmasa qiymət daxil edin")
      .test("productCost", "Qiymət 0-dan böyük olmalıdır", function (value) {
        if (value < this.parent.price) {
          return false;
        }
        return true;
      }),

    stock: Yup.number()
      .min(1, "Say 0-dan böyük olmalıdır")
      .required("Zəhmət olmasa say daxil edin"),
    commissionRate: Yup.number()
      .test("commissionRate", "Komissiya - ola bilmez", (value) => {
        if (value < 0) {
          return false;
        }
        return true;
      })
      .max(50, "Komissiya 50-dən böyük ola bilməz"),
  });

 


  const handleSubmit = (values, actions) => {
    agent.Discount[type](values)
      .then(() => {
        actions.setSubmitting(false);
        setSuccessSB(true);
        actions.resetForm();
        setLoading(false);
        navigate("/discount/discounts");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {type === "create" ? "Yeni Endirim" : "Endirimin Redaktəsi"}
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={4} px={25}>
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={(values, actions) => {
                    setLoading(true);
                    handleSubmit(values, actions);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form>
                      <MDBox component="div" role="form">
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                label="Endirimin adı"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                as={MDInput}
                                variant="standard"
                                fullWidth
                              />
                              {}
                              {touched.name && errors.name && errorMessages(errors.name)}
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4}>
                            <MDBox mb={2}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    label="Başlama tarixi"
                                    value={dayjs(values.startDate)}
                                    onChange={(newValue) => {
                                      setFieldValue("startDate", newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <MDBox mb={2}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    label="Bitmə tarixi"
                                    value={dayjs(values.endDate)}
                                    onChange={(newValue) => {
                                      setFieldValue("endDate", newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </MDBox>
                          </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4}>
                            <MDBox mb={2}>
                              <Field
                                type="number"
                                name="percentage"
                                id="percentage"
                                label="Endirim faizi"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.percentage}
                                as={MDInput}
                                variant="standard"
                                fullWidth
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <MDBox mt={4} mb={1}>
                          <MDButton
                            disabled={loading}
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                            {type === "create" ? "Əlavə Et" : "Yenilə"}
                          </MDButton>
                        </MDBox>
                        <MDBox mt={1} mb={1} style={{ display: loading ? "block" : "none" }}>
                          <LinearProgress
                            color="info"
                            hidden={true}
                            style={{
                              width: "50%",
                              margin: "auto",
                              position: "relative",
                            }}
                          />
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
                <MDSnackbar
                  color="success"
                  icon="check"
                  title="Uğurlu Əməliyyat"
                  content={type === "create" ? "Endirim əlavə edildi" : "Endirim yeniləndi"}
                  dateTime={new Date().toLocaleString().split(",")[0]}
                  open={successSB}
                  onClose={closeSuccessSB}
                  close={closeSuccessSB}
                  bgWhite
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default DiscountForm;
