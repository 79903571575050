import React, { useMemo, useState, useCallback, useEffect } from "react";
import MaterialReactTable from "material-react-table";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Edit, Visibility, Add } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";

// Agent import
import agent from "api/agent";

import {
  IconButton,
  Tooltip,
  Toolbar,
  Box,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";

// Formik and Yup validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import SelectBox from "components/MDSelectBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// react-router-dom components
import {useNavigate } from "react-router-dom";

import axios from "axios";

// const queryClient = new QueryClient();
const Example = () => {
  const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [productId, setProductId] = useState(null);
  const [addCouponCodeModalOpen, setAddCouponCodeModalOpen] = useState(false);
  const [addProductToDiscountModalOpen, setAddProductToDiscountModalOpen] = useState(false);
  const [productIds, setProductIds] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [loading, setLoading] = useState(false);

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL("api/Product/GetAll", "https://api.ibu.az/");
      // bu
      fetchURL.searchParams.set("start", `${pagination.pageIndex + 1}`);
      fetchURL.searchParams.set("size", `${pagination.pageSize}`);
      // bu son
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      const response = await agent.Product.getAllProducts(fetchURL.search);
      return response;
    },
    keepPreviousData: true,
  });

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, //accessorFn used to join multiple data into a single cell
        id: "Name", //id is still required when using accessorFn instead of accessorKey
        header: "Ad",
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              alt="avatar"
              height={50}
              src={row.original.pictureUrl}
              loading="lazy"
              style={{ borderRadius: "50%" }}
            />
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: "price",
        header: "Qiymət",
        id: "Price",
        columnFilterModeOptions: ["between", "greaterThan", "lessThan"], 
        filterFn: "between",
      },
      {
        accessorKey: "cosmetologOnly",
        header: "Kosmetoloqlar üçün",
        id: "CosmetologOnly",
        Cell: ({ cell }) => {
          return cell.getValue() ? "Bəli" : "Xeyr";
        },
      },
      {
        accessorKey: "stock",
        header: "Stok",
        id: "Stock",
        Cell: ({ cell }) => {
          // if stock is less than 10, color the cell red and bold the text "Stok bitmək üzrədir"
          if (cell.getValue() < 10) {
            return (
              <MDTypography
                fontWeight="bold"
                sx={{
                  color: "red",
                }}
              >
                Stok bitmək üzrədir
                {cell.getValue()}
              </MDTypography>
            );
          }
          return cell.getValue();
        },
      },
      {
        accessorKey: "brand",
        header: "Brend",
        id: "Brand.Name",
      },
      {
        accessorKey: "company",
        header: "Şirkət",
        id: "Brand.Company.Name",
      },
    ],
    []
  );

  const handleAddProductToDiscount = useCallback((table) => {
    const productIds = table.getSelectedRowModel().flatRows.map((row) => row.original.id);
    setProductIds(productIds);
    setAddProductToDiscountModalOpen(true);
  }, []);

  const handleDeleteRow = useCallback(
    async (ids) => {
      if (
        !confirm(
          `${ids.length} qədər məhsul silinəcək. Silməyə əminsiniz?`
        )
      ) {
        return;
      }
       try {
          setLoading(true);
          await agent.Product.delete(ids);
          refetch();
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
    },
    [refetch]
  );

  // bu
  const rowsPerPageOptions = [];
  for (let i = 10; i <= data?.totalCount; i += 10) {
    rowsPerPageOptions.push(i);
  }
  rowsPerPageOptions.push(data?.totalCount);
 // bu son
  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data?.items ?? []}
        initialState={{ showColumnFilters: true }}
        manualFiltering
        manualPagination
        manualSorting
        enableRowActions
        enableRowSelection
        muiSelectCheckboxProps={{
          color: "secondary",
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderRowActions={({ row }) => (
          <Toolbar>
            <IconButton onClick={() => 
            navigate("/product/detail", {
              state: {
                id: row.original.id,
              },
            })
              }>
              <Visibility
                sx={{
                  color: "green",
                }}
              />
            </IconButton>
            <IconButton 
            onClick={() => 
            navigate("/product/edit", {
              state: {
                id: row.original.id,
              },
            })
            }>
              <Edit
                sx={{
                  color: "orange",
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                setProductId(row.original.id);
                setAddCouponCodeModalOpen(true);
              }}
            >
              <Add
                sx={{
                  color: "blue",
                }}
              />
            </IconButton>
          </Toolbar>
        )}
        renderTopToolbarCustomActions={({ table }) => {
          const handleDeactivate = () => {
            const ids = table.getSelectedRowModel().flatRows.map((item)=>{
              return item.original.id;
            })
            handleDeleteRow(ids);
          };

          return (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <MDButton
                color="error"
                variant="contained"
                disabled={table.getSelectedRowModel().flatRows.length < 1}
                onClick={handleDeactivate}
              >
                Silmək
              </MDButton>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              <Button
                color="info"
                onClick={() => {
                  handleAddProductToDiscount(table);
                }}
                variant="contained"
                disabled={table.getSelectedRowModel().flatRows.length < 1}
              >
                Endirim et
              </Button>
            </div>
          );
        }}
        // bu
        muiTablePaginationProps={{
          rowsPerPageOptions: rowsPerPageOptions,
          showFirstButton: true,
          showLastButton: true,
        }}
        rowCount={data?.totalCount ?? 0}
        // bu son
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching || loading,
          sorting,
        }}
      />
      <CreateCouponCodeModal
        open={addCouponCodeModalOpen}
        onClose={() => setAddCouponCodeModalOpen(false)}
        productId={productId}
      />

      <CreateDiscountModal
        open={addProductToDiscountModalOpen}
        onClose={() => setAddProductToDiscountModalOpen(false)}
        productIds={productIds}
      />
    </>
  );
};

const CreateCouponCodeModal = ({ open, onClose, productId }) => {
  const handleSubmit = (values) => {
    values.targetId = productId;
    agent.Coupon.addCoupon(values)
      .then((response) => {
        console.log(response);
        onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const initialValues = {
    name: "",
    description: "",
    code: "",
    discountPercentage: 0,
    scope: "Product",
    endDate: new Date(),
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form>
          <Dialog open={open}>
            <DialogTitle textAlign="center">
              <MDTypography variant="h6">Kupon Əlavə et</MDTypography>
            </DialogTitle>
            <DialogContent>
              <Stack
                sx={{
                  width: "100%",
                  minWidth: { xs: "300px", sm: "360px", md: "400px" },
                  gap: "1.5rem",
                }}
              >
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <Field
                      type="text"
                      name="name"
                      id="name"
                      label="Kuponun adı"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                    {}
                    {touched.name && errors.name && errorMessages(errors.name)}
                  </MDBox>
                  <MDBox mb={2}>
                    <Field
                      type="text"
                      name="description"
                      id="description"
                      label="Kupon haqqında"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                    {}
                    {touched.description && errors.description && errorMessages(errors.description)}
                  </MDBox>
                  <MDBox mb={2}>
                    <Field
                      type="text"
                      name="code"
                      id="code"
                      label="Kupon Kodu"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.code}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                    {}
                    {touched.code && errors.code && errorMessages(errors.code)}
                  </MDBox>
                  <MDBox mb={2}>
                    <Field
                      type="number"
                      name="discountPercentage"
                      id="discountPercentage"
                      label="Endirim Faizi"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.discountPercentage}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                    {}
                    {touched.discountPercentage &&
                      errors.discountPercentage &&
                      errorMessages(errors.discountPercentage)}
                  </MDBox>
                  <MDBox mb={2}>
                    <Field
                      type="date"
                      name="endDate"
                      id="endDate"
                      label="Bitiş tarixi"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.endDate}
                      as={MDInput}
                      variant="standard"
                      fullWidth
                    />
                    {}
                    {touched.endDate && errors.endDate && errorMessages(errors.endDate)}
                  </MDBox>
                </Grid>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ p: "1.25rem" }}>
              <Button onClick={onClose}>Ləğv Et</Button>
              <Button color="info" onClick={handleSubmit} variant="contained">
                Əlavə Et
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

const CreateDiscountModal = ({ open, onClose, productIds }) => {
  const [discount, setDiscount] = useState([]);
  useEffect(() => {
    agent.Discount.alldiscounts().then((response) => {
      const discount = response?.map((discount) => {
        return {
          value: discount.id,
          label: discount.name,
        };
      });
      setDiscount(discount);
    });
  }, []);

  const handleSubmit = (values) => {
    values.productIds = productIds;
    agent.Discount.addProductToDiscount(values)
      .then((response) => {
        console.log(response);
        onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const initialValues = {
    discountId: null,
  };

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form>
          <Dialog open={open}>
            <DialogTitle textAlign="center">
              <MDTypography variant="h6">Endirim Əlavə et</MDTypography>
            </DialogTitle>
            <DialogContent>
              <Stack
                sx={{
                  width: "100%",
                  minWidth: { xs: "300px", sm: "360px", md: "400px" },
                  gap: "1.5rem",
                }}
              >
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <Field
                      name="discountId"
                      label="Endirimlər"
                      component={SelectBox}
                      options={discount ? discount : []}
                      onChange={(value) => setFieldValue("discountId", value)}
                    />
                    {touched.discountId && errors.discountId && errorMessages(errors.discountId)}
                  </MDBox>
                </Grid>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ p: "1.25rem" }}>
              <Button onClick={onClose}>Cancel</Button>
              <Button color="info" onClick={handleSubmit} variant="contained">
                Əlavə Et
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

const Products = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Məhsullar
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Products;
