import React, { useEffect, useRef, useState } from 'react';


import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LinearProgress from "@mui/material/LinearProgress";
// Ibeauty Admin Agent 
import agent from "api/agent";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { Autocomplete, DrawingManager, GoogleMap, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api';
import deleteIcon from 'assets/images/remove.png';
import { Icon } from '@mui/material';
import MDBadge from 'components/MDBadge';
const libraries = ['places', 'drawing'];

function MapSettings() {
    const mapRef = useRef();
    const polygonRefs = useRef([]);
    const activePolygonIndex = useRef();
    const autocompleteRef = useRef();
    const drawingManagerRef = useRef();


    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyC5Qr2F8FCpfkIfXzwNyMah8WC4vwlAf4o',
        libraries
    });

    const [loading, setLoading] = useState(false);

    const [polygons, setPolygons] = useState([]);

    // get polygons from api
    useEffect(() => {
      const getPolygons = async () => {
        const response = await agent.PermittedArea.GetPermittedArea();
        setPolygons([response]);
      };
      getPolygons();
    }, []);

    const [marker , setMarker] = useState( { lat: 40.4093,lng: 49.8671, });

    const defaultCenter = {
        lat: 40.4093,
        lng: 49.8671,
    }

    const [center, setCenter] = useState(defaultCenter);

    const containerStyle = {
        width: '100%',
        height: '400px',
    }

    const autocompleteStyle = {
        boxSizing: 'border-box',
        border: '1px solid transparent',
        width: '240px',
        height: '38px',
        padding: '0 12px',
        borderRadius: '3px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        fontSize: '14px',
        outline: 'none',
        textOverflow: 'ellipses',
        position: 'absolute',
        right: '8%',
        top: '11px',
        marginLeft: '-120px',
    }

    const deleteIconStyle = {
        cursor: 'pointer',
        backgroundImage: `url(${deleteIcon})`,
        height: '24px',
        width: '24px',
        marginTop: '5px', 
        backgroundColor: '#fff',
        position: 'absolute',
        top: "2px",
        left: "52%",
        zIndex: 99999
    }

    const polygonOptions = {
        fillOpacity: 0.3,
        fillColor: '#FE9E19',
        strokeColor: '#FE9E19',
        strokeWeight: 2,
        draggable: true,
        editable: true
    }

    const drawingManagerOptions = {
        polygonOptions: polygonOptions,
        drawingControl: true,
        drawingControlOptions: {
            position: window.google?.maps?.ControlPosition?.TOP_CENTER,
            drawingModes: [
                window.google?.maps?.drawing?.OverlayType?.POLYGON
            ]
        }
    }

    const onLoadMap = (map) => {
        mapRef.current = map;
    }

    const onLoadPolygon = (polygon, index) => {
        polygonRefs.current[index] = polygon;
    }

    const onClickPolygon = (index) => {
        activePolygonIndex.current = index; 
    }

    const onLoadAutocomplete = (autocomplete) => {
        autocompleteRef.current = autocomplete;
    }

    const onPlaceChanged = () => {
        const { geometry } = autocompleteRef.current.getPlace();
        const bounds = new window.google.maps.LatLngBounds();
        if (geometry.viewport) {
            bounds.union(geometry.viewport);
        } else {
            bounds.extend(geometry.location);
        }
        mapRef.current.fitBounds(bounds);
    }

    const onLoadDrawingManager = drawingManager => {
        drawingManagerRef.current = drawingManager;
    }

    const onOverlayComplete = ($overlayEvent) => {
        drawingManagerRef.current.setDrawingMode(null);
        if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYGON) {
            const newPolygon = $overlayEvent.overlay.getPath()
                .getArray()
                .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }))

            // start and end point should be same for valid geojson
            const startPoint = newPolygon[0];
            newPolygon.push(startPoint);
            $overlayEvent.overlay?.setMap(null);
            setPolygons([newPolygon]);
        }
    }

    const onDeleteDrawing = () => {  
        const filtered = polygons.filter((polygon, index) => index !== activePolygonIndex.current) 
        setPolygons(filtered)
    }

    const onEditPolygon = (index) => {
        const polygonRef = polygonRefs.current[index];
        if (polygonRef) {
            const coordinates = polygonRef.getPath()
                .getArray()
                .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));

            const allPolygons = [...polygons];
            allPolygons[index] = coordinates;
            setPolygons(allPolygons);
        }
    }

    const initialValues = {
        coordinates: []
    }


   const handleSubmit = async (values) => {
        setLoading(true);
        await agent.PermittedArea.AddOrUpdatePermittedArea(values);
        setLoading(false);
    }
        

    const errorMessages = (error) => {
        return (
          <>
          <MDTypography variant="body2" color="error">
            {error}
          </MDTypography>
          </>
        )
      }

    return (
        <DashboardLayout>
        <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                   Xəritədə əhatə bölgəsini seçin
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={4} px={5}>
                 {
                      isLoaded
                      ?
                      <div className='map-container' style={{ position: 'relative' }}>
                          {
                              drawingManagerRef.current
                              &&
                              <div
                                  onClick={onDeleteDrawing}
                                  title='Delete shape'
                                  style={deleteIconStyle}>
                              </div>
                          }
                          <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <Form>
                          <GoogleMap
                              zoom={10}
                              center={center}
                              onLoad={onLoadMap}
                              
                              mapContainerStyle={containerStyle}
                              onTilesLoaded={() => setCenter(null)}
                          >
                              <DrawingManager
                                  onLoad={onLoadDrawingManager}
                                  onOverlayComplete={(event) =>{
                                     onOverlayComplete(event)
                                     setFieldValue('coordinates', event.overlay.getPath().getArray())
                                    }}
                                  options={drawingManagerOptions}
                              />
                              {
                                  polygons.map((iterator, index) => (
                                      <Polygon
                                          key={index}
                                          onLoad={(event) => onLoadPolygon(event, index)}
                                          onMouseDown={() => onClickPolygon(index)}
                                          onMouseUp={() =>  onEditPolygon(index)}
                                          onDragEnd={() => onEditPolygon(index)}
                                          options={polygonOptions}
                                          paths={iterator}
                                          draggable
                                          editable
                                      />
                                  ))
                              }
                              <Autocomplete
                                  onLoad={onLoadAutocomplete}
                                  onPlaceChanged={onPlaceChanged}
                              >
                                  <input
                                      type='text'
                                      placeholder='Search Location'
                                      style={autocompleteStyle}
                                  />
                              </Autocomplete>
                          </GoogleMap>
                          <Grid 
                          container 
                          pt={6} 
                          pb={3}
                          spacing={2}>
                        </Grid>
                        <MDBox mt={4} mb={1}>
                          <MDButton
                            disabled={loading}
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                            Yadda saxla
                          </MDButton>
                          
                        </MDBox>
                    </Form>
                    )}
                </Formik>
                      </div>
                      :
                      null
                 }
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    );
}

export default MapSettings;