import React, { useMemo, useState, useCallback } from "react";
import MaterialReactTable from "material-react-table";
import { Box, Stack } from "@mui/material";
import { IconButton, Tooltip, Button, Toolbar } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Add, Delete, Edit, Visibility } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//Date Picker Imports
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import agent from "api/agent";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const Example = () => {
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        "api/ProductDiscount/DiscountsWithProducts",
        "https://api.ibu.az/"
      );
      fetchURL.searchParams.set("start", `${pagination.pageIndex + 1}`);
      fetchURL.searchParams.set("size", `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      const response = await agent.Discount.discountsWithProducts(fetchURL.search);
      return response;
    },
    keepPreviousData: true,
  });

  const columns = useMemo(
    () => [
      {
        header: "Endirimin adı",
        accessorKey: "name",
        id: "Name",
      },
      {
        accessorKey: "discountPercentage",
        header: "Endirim faizi",
        id: "DiscountPercentage",
      },
      {
        accessorFn: (row) => new Date(row.startDate), //convert to Date for sorting and filtering
        id: "StartDate",
        header: "Başlama tarixi",
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: "Filter Mode: Less Than",
                  sx: { minWidth: "120px" },
                  variant: "standard",
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorFn: (row) => new Date(row.endDate), //convert to Date for sorting and filtering
        id: "EndDate",
        header: "Bitmə tarixi",
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: "Filter Mode: Less Than",
                  sx: { minWidth: "120px" },
                  variant: "standard",
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  const handleDeleteRow = useCallback(
    async (table) => {
      if (
        !confirm(`Bu ${table.getSelectedRowModel().flatRows.length} endirimi silməyə əminsiniz?`)
      ) {
        return;
      }
      let selected = table.getSelectedRowModel().flatRows.map((row) => {
        return row.original.id;
      });
    try {
      await agent.Discount.delete(selected);
      refetch()
    } catch (error) {
      
    }
    },
    [refetch]
  );

  const handleRemoveDiscountFromProduct = async (productId) => {
    const response = await agent.Discount.removeDiscountFromProduct(productId);
    if (response) {
      refetch();
    }
  };
 
   const rowsPerPageOptions = [];
   for (let i = 10; i <= data?.totalCount; i += 10) {
     rowsPerPageOptions.push(i);
   }
   rowsPerPageOptions.push(data?.totalCount);


  return (
    <MaterialReactTable
      columns={columns}
      data={data?.items ?? []} //data is undefined on first render
      manualFiltering
      manualPagination
      manualSorting
      enableSelectAll
      enableRowSelection
      enableColumnFilterModes
      enableColumnResizing
      filterFromLeafRows //apply filtering to all rows instead of just parent rows
      initialState={{
        expanded: false,
        showColumnFilters: true,
      }}
      editingMode="modal" //default
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      renderDetailPanel={({ row }) => (
        <Box
          sx={{
            display: "grid",
            margin: "auto",
            gridTemplateColumns: "1fr 1fr",
            width: "100%",
          }}
        >
          {/* // i want example this view  
            Mehsulun adi: {row.original.name} Grup Adi : {row.original.groupName} Action : Delete Button
             */}
          <Stack spacing={2}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="h6" color="info">
                Məhsulun adı
              </MDTypography>
              <MDTypography variant="h6" color="info">
                Məhsulun aid olduğu brand
              </MDTypography>
              <MDTypography color="white">Əməliyyatlar</MDTypography>
            </Box>
            {row.original?.products?.map((product) => (
              <Box key={product.id} sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6">{product.name}</MDTypography>
                <MDTypography variant="h6">{product.brand}</MDTypography>
                <MDButton
                  variant="outlined"
                  color="error"
                  onClick={() => handleRemoveDiscountFromProduct(product.id)}
                >
                  Sil
                </MDButton>
              </Box>
            ))}
          </Stack>
        </Box>
      )}
      muiSelectCheckboxProps={{
        color: "secondary",
      }}
      renderTopToolbarCustomActions={({ table }) => {
        const handleDeactivate = () => {
          handleDeleteRow(table).then(() => {});
        };

        return (
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <Button
              color="error"
              disabled={table.getSelectedRowModel().flatRows.length < 1}
              onClick={handleDeactivate}
              variant="contained"
            >
              Silmək
            </Button>
            <Tooltip arrow title="Refresh Data">
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      }}
         muiTablePaginationProps={{
          rowsPerPageOptions: rowsPerPageOptions,
          showFirstButton: true,
          showLastButton: true,
        }}
        rowCount={data?.totalCount ?? 0}
      state={{
        // rowSelection,
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isFetching,
        sorting,
      }}
    />
  );
};

const Discounts = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Endirimlər
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Discounts;
